import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DeviceVersionComponent } from "../components/device/version/DeviceVersionComponent";
import { retrieveFirmwareVersionFromServer } from "../utils/retrieveFirmwareVersion";

interface Props {}

const DeviceVersionPage = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    retrieveFirmwareVersionFromServer(dispatch);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DeviceVersionComponent />
      </Grid>
    </Grid>
  );
};

export { DeviceVersionPage };
