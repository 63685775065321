import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { IMessage } from "@stomp/stompjs";
import { useEffect, useState } from "react";
import { COBRA_API } from "../../../api/config";
import {
  AbstractMonitorMessage,
  AbstractStatusPayload,
  DeviceConfiguration,
  DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum,
  MonitorPacketDto,
} from "../../../cobra-backend-client";
import { DEVICE_STATUS_INDEX } from "../../../constants/common";
import { TOPIC_MONITORING } from "../../../constants/topics";
import { useCustomSubscriptionManager } from "../../../hooks/useCustomSubscriptionManager";
import { CustomAlert } from "../../common/CustomAlert";

interface Props {
  deviceId: number;
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  showAngleCalibrationProgress: boolean;
  setShowAngleCalibrationProgress: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}
const AngleCalibration = (props: Props) => {
  const { subscribeToTopic } = useCustomSubscriptionManager();

  const [platformAngle, setPlatformAngle] = useState<number>();
  const [platformAngleDisplayValue, setPlatformAngleDisplayValue] =
    useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    subscribeToTopic(TOPIC_MONITORING, onMonitorMessage);
  }, []);

  useEffect(() => {
    if (platformAngle != null) {
      const angleValue = platformAngle != 0 ? platformAngle / 100 : 0;
      setPlatformAngleDisplayValue(angleValue.toFixed(1) + "°");
    }
  }, [platformAngle]);

  const onMonitorMessage = (message: IMessage) => {
    const monitorPacket = JSON.parse(message.body) as MonitorPacketDto;

    if (
      props.deviceId == monitorPacket.deviceId &&
      monitorPacket.payloads &&
      monitorPacket.payloads.length > 0
    ) {
      const monitorMessageDto: AbstractMonitorMessage | undefined =
        monitorPacket.payloads.find(
          (monitorMessage) => monitorMessage.index === DEVICE_STATUS_INDEX
        );
      if (!monitorMessageDto) return;
      if (monitorMessageDto.index === DEVICE_STATUS_INDEX) {
        const abstractStatusPayload =
          monitorMessageDto.payload as AbstractStatusPayload;
        setPlatformAngle(abstractStatusPayload.platformAngle);
      }
    }
  };

  const onSetZeroClick = () => {
    props.setShowAngleCalibrationProgress(true);
    updateAngleCallibration();
  };

  const updateAngleCallibration = async () => {
    if (platformAngle == null || props.deviceId == null) return;
    try {
      const signedDeviceConfiguration =
        await COBRA_API.DeviceConfiguration.getSignedDeviceConfiguration(
          +props.deviceId
        );
      const deviceConfiguration = signedDeviceConfiguration.data.configuration;
      if (!deviceConfiguration) {
        setAlertMessage("Configuration can not be exported");
        setIsErrorAlert(true);
        setShowAlert(true);
        return;
      }
      const angleCorrection = deviceConfiguration?.imu?.angleCorrection ?? 0;
      const newAngleCorrection = angleCorrection - platformAngle;

      const newDeviceConfiguration: DeviceConfiguration = {
        ...deviceConfiguration,
        imu: {
          ...deviceConfiguration?.imu,
          angleCorrection: newAngleCorrection,
        },
      };
      updateDeviceConfiguration(props.deviceId, newDeviceConfiguration);
      props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
    } catch (e) {
      props.setShowAngleCalibrationProgress(false);
      setAlertMessage("Configuration can not be exported");
      setIsErrorAlert(true);
      setShowAlert(true);
    }
  };

  const updateDeviceConfiguration = async (
    deviceId: number,
    deviceConfiguration: DeviceConfiguration
  ) => {
    try {
      await COBRA_API.DeviceMonitor.disableMonitoring(
        DEVICE_STATUS_INDEX.toString(),
        props.deviceId
      );

      const deviceConfigurationUpdateDto =
        await COBRA_API.DeviceConfiguration.updateDeviceConfiguration(
          deviceId,
          deviceConfiguration
        );
      if (
        deviceConfigurationUpdateDto.data.deviceConfigurationUpdateResponse ===
        DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum.NoErr
      ) {
        setIsErrorAlert(false);
        setAlertMessage("Angle was calibrated successfully");
      } else {
        setIsErrorAlert(true);
        setAlertMessage("Angle was not calibrated");
      }
    } catch (e) {
      setIsErrorAlert(true);
    }
    props.setShowAngleCalibrationProgress(false);
    setShowAlert(true);

    await COBRA_API.DeviceMonitor.enableMonitoring(
      DEVICE_STATUS_INDEX.toString(),
      props.deviceId
    );
  };

  return (
    <>
      <Grid container flexGrow={1}>
        <Grid item xs={6} sm={6}>
          <Typography>
            Angle calibration
            {props.showAngleCalibrationProgress && (
              <CircularProgress size={20} sx={{ ml: 1 }} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} textAlign="right">
          {platformAngle != null && (
            <>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography sx={{ mr: 1 }}>
                  {platformAngleDisplayValue}
                </Typography>

                <Button
                  variant="contained"
                  size="small"
                  onClick={onSetZeroClick}
                  disabled={
                    props.showAngleCalibrationProgress ||
                    !props.updatedDeviceConfiguration.imu?.plSensEnable
                  }
                >
                  Set '0'
                </Button>
              </Box>
            </>
          )}
        </Grid>
        {showAlert && (
          <CustomAlert
            autoHideDurationMs={5000}
            onAlertClose={() => setShowAlert(false)}
            alertSeverity={isErrorAlert ? "error" : "success"}
            alertText={alertMessage}
          />
        )}
      </Grid>
    </>
  );
};

export { AngleCalibration };
