import { Chip, Grid, Typography } from "@mui/material";
import {
  MonitorErrorCode,
  MonitorErrorCodeMessages,
} from "../../../models/dto/ws/monitoring/MonitorErrorCode";

interface Props {
  errorCode: MonitorErrorCode;
}

const Warning = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Warning </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Chip
          label={MonitorErrorCodeMessages[props.errorCode] ?? "Unknown"}
          variant="outlined"
          color={
            props.errorCode !== MonitorErrorCode.NO_ERROR ? "error" : "success"
          }
        />
      </Grid>
    </>
  );
};

export { Warning };
