export enum FirmwareUpdateState {
  START_FIRMWARE_UPDATE = "Firmware update (bootloader) on the microcontroller is started.",
  OLD_FIRMWARE_DELETED = "Old firmware is deleted.",
  EEPROM_DELETED = "EEPROM  is deleted.",
  NEW_FIRMWARE_START_INSTALLING = "New firmware will be programmed.",
  NEW_FIRMWARE_CAN_NOT_BE_INSTALLED = "New firmware could not be programmed, update aborted.",
  NEW_FIRMWARE_INSTALLED = "New firmware has been successfully installed, update completed.",
  CUSTOM_ERROR_FIRMWARE_PARSE = "Fimware update can not be started, firmwares can not be parsed",
  CUSTOM_ERROR_DEVICE_CONFIGURATION_EXPORT = "Device Configuration can not be exported from the device, update can not be started",
  CUSTOM_ERROR_DEVICE_TRANSMITTER_MEMORY_CONFIGURATION_EXPORT = "Device Bonded Handheld Serial Numbers Configuration can not be exported from the device, update can not be started",
  CUSTOM_ERROR_DEVICE_CONFIGURATION_IMPORT = "Device Configuration can not be imported to the device",
  CUSTOM_ERROR_DEVICE_TRANSMITTER_MEMORY_CONFIGURATION_IMPORT = "Device Bonded Handheld Serial Numbers Configuration can not be imported to the device",
  CUSTOM_ERROR_DEVICE_CONFIGURATION_DEVICE_VERSION_INCOMPATIBLE = "Device Configuration version is incomapitble, update can not be started",
}

export const getFirmwareUpdateStatesEnumValueByKey = (
  key: string
): FirmwareUpdateState | undefined => {
  return (
    FirmwareUpdateState as {
      [key: string]: FirmwareUpdateState;
    }
  )[key];
};
