import { Grid, Typography } from "@mui/material";

interface Props {
  propertyName: string;
  propertyInstalledVersion: string;
  propertyNewVersion?: string;
  title?: string;
}

const DeviceVersionLayout = (props: Props) => {
  return (
    <Grid container spacing={1}>
      {props.title && (
        <>
          <Grid item xs={6}>
            <Typography variant="h6">{props.title}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Installed version
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              New version
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={6} sx={{ mb: 0.5 }}>
        <Typography> {props.propertyName}</Typography>
      </Grid>
      <Grid item xs={3} sx={{ mb: 0.5 }}>
        <Typography> {props.propertyInstalledVersion} </Typography>
      </Grid>
      <Grid item xs={3} sx={{ mb: 0.5 }}>
        <Typography>
          {props.propertyNewVersion ? props.propertyNewVersion : ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { DeviceVersionLayout };
