import { Alert, AlertTitle, LinearProgress } from "@mui/material";
import { IMessage } from "@stomp/stompjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AbstractMonitorMessage,
  MonitorPacketDto,
} from "../../../cobra-backend-client";
import { DEVICE_STATUS_INDEX } from "../../../constants/common";
import { TOPIC_MONITORING } from "../../../constants/topics";
import { useCustomSubscriptionManager } from "../../../hooks/useCustomSubscriptionManager";
import { RootState } from "../../../stores/store.js";
import { DeviceStatus } from "./DeviceStatus";

const MONITOR_MESSAGE_TIMEOUT = 4500;

const DeviceStatusContainer = () => {
  const deviceId = useSelector((state: RootState) => state.device.deviceId);
  const { subscribeToTopic } = useCustomSubscriptionManager();

  const [monitorMessage, setMonitorMessage] =
    useState<AbstractMonitorMessage>();
  const [isMonitorMessageTimeout, setIsMonitorMessageTimeout] = useState(false);

  let monitorMessageTimeout: NodeJS.Timeout;

  useEffect(() => {
    subscribeToMonitor();
  }, []);

  const startMonitorMessageTimeout = () => {
    setIsMonitorMessageTimeout(false);
    if (monitorMessageTimeout) {
      clearTimeout(monitorMessageTimeout);
    }
    monitorMessageTimeout = setTimeout(
      handleMonitorTimeout,
      MONITOR_MESSAGE_TIMEOUT
    );
  };

  const clearMonitorMessageTimeout = () => {
    if (monitorMessageTimeout) {
      clearTimeout(monitorMessageTimeout);
    }
  };

  const handleMonitorTimeout = () => {
    if (!deviceId) return;
    setIsMonitorMessageTimeout(true);
  };

  const subscribeToMonitor = () => {
    subscribeToTopic(TOPIC_MONITORING, onMonitorMessage);
    startMonitorMessageTimeout();
  };

  const onMonitorMessage = (message: IMessage) => {
    clearMonitorMessageTimeout();
    const monitorPacket = JSON.parse(message.body) as MonitorPacketDto;

    if (
      deviceId == monitorPacket.deviceId &&
      monitorPacket.payloads &&
      monitorPacket.payloads.length > 0
    ) {
      const monitorMessageDto: AbstractMonitorMessage | undefined =
        monitorPacket.payloads.find(
          (monitorMessage) => monitorMessage.index === DEVICE_STATUS_INDEX
        );
      if (!monitorMessageDto) return;
      if (monitorMessageDto.index === DEVICE_STATUS_INDEX) {
        setMonitorMessage(monitorMessageDto);
      }
    }
  };

  return (
    <>
      {monitorMessage != null ? (
        <DeviceStatus monitorMessage={monitorMessage} />
      ) : isMonitorMessageTimeout ? (
        <>
          <Alert severity="error">
            <AlertTitle>Device Status is unavailable</AlertTitle>
            Please update your device to the newest Software-Version to use all
            Functions of this application.
          </Alert>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export { DeviceStatusContainer };
