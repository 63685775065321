import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as CabinSwitchIcon } from "../../../assets/Fahrerhausschalter.svg";
import { ReadOnlyCheckBox } from "../../common/ReadOnlyCheckBox";
import { CabinSwitch } from "../../../cobra-backend-client";

interface Props {
  cabinSwitch: CabinSwitch;
  cabinSwitchSensor: boolean;
}

const CabinSwitchComponent = (props: Props) => {
  return (
    <>
      {props.cabinSwitchSensor && (
        <>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <Typography>
              Cabin Switch
              <CabinSwitchIcon
                style={{ width: "24px", height: "24px", marginLeft: "10px" }}
              />
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box
                component="span"
                sx={{ pr: 1.5, display: "flex", alignItems: "center" }}
              >
                Switch
                <ReadOnlyCheckBox
                  checked={props.cabinSwitch.inSwitch ?? false}
                />
              </Box>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                LED
                <ReadOnlyCheckBox checked={props.cabinSwitch.outLed ?? false} />
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export { CabinSwitchComponent };
