import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { COBRA_API } from "../../api/config";
import { DEVICE_STATUS_INDEX } from "../../constants/common";
import {
  CONTROLLER_INSTALLATION_PAGE,
  DEVICE_STATUS_PAGE,
  LOGIN_PAGE,
} from "../../constants/pages";
import { onlineDeviceDrawerMenuItems } from "../../routes/onlineDeviceDrawerMenuItems";
import { clearDeviceSlice } from "../../stores/slices/deviceSlice";
import { setDrawerOpen } from "../../stores/slices/drawerSlice";
import { RootState } from "../../stores/store";
import { customizedTheme, drawerWidth } from "../../theme/theme";
import { IconButtonWithTooltip } from "../common/IconButtonWithTooltip";
import { AppDrawerMenuItem } from "./AppDrawerMenuItem";
import { AppDrawerOnlineDeviceOrAuthenticatedMenuItem } from "./AppDrawerOnlineDeviceOrAuthenticatedMenuItem";
import { DrawerHeader } from "./DrawerHeader";

const AppDrawer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const open = useSelector((state: RootState) => state.drawer.open);
  const deviceId = useSelector((state: RootState) => state.device.deviceId);
  const isDeviceOnline = useSelector(
    (state: RootState) => state.device.isDeviceOnline
  );
  const userToken = useSelector((state: RootState) => state.user.token);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(customizedTheme.breakpoints.down("md"));

  const stopDevieStatusMonitor = async () => {
    if (!deviceId || !location) return;
    if (
      location.pathname === DEVICE_STATUS_PAGE ||
      location.pathname === CONTROLLER_INSTALLATION_PAGE
    ) {
      try {
        await COBRA_API.DeviceMonitor.disableMonitoring(
          DEVICE_STATUS_INDEX.toString(),
          +deviceId
        );
      } catch (error) {}
    }
  };

  return (
    <Box component="nav" sx={{ width: 0, flexShrink: 0 }}>
      <Drawer
        sx={{
          width: `${drawerWidth}px`,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: `${drawerWidth}px`,
            boxSizing: "border-box",
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        variant={isSmallScreen ? "temporary" : "persistent"}
        // This works only for temporary drawer!
        onClose={(_, reason) =>
          reason === "backdropClick" && dispatch(setDrawerOpen(false))
        }
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Grid container spacing={0} alignItems="center">
            {isDeviceOnline ? (
              <Grid item xs={8}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography> Device {deviceId}</Typography>
                  <Chip
                    sx={{ ml: 1 }}
                    label={"online"}
                    variant="outlined"
                    color={"success"}
                    size="small"
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={10}>
                <Typography>No connected device</Typography>
              </Grid>
            )}

            {isDeviceOnline && (
              <Grid item xs={2}>
                <IconButtonWithTooltip
                  icon={<PowerSettingsNewIcon />}
                  color="error"
                  tooltipText="Disconnect from device"
                  onClick={() => {
                    stopDevieStatusMonitor();
                    navigate(LOGIN_PAGE);
                    dispatch(clearDeviceSlice());
                  }}
                />
              </Grid>
            )}

            <Grid item xs={2}>
              <IconButton onClick={() => dispatch(setDrawerOpen(false))}>
                {customizedTheme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        <List>
          {isDeviceOnline && (
            <>
              {onlineDeviceDrawerMenuItems.map((item, index) => (
                <AppDrawerMenuItem key={index} item={item} />
              ))}
            </>
          )}

          <AppDrawerOnlineDeviceOrAuthenticatedMenuItem
            isDeviceOnline={isDeviceOnline}
          />
        </List>
      </Drawer>
    </Box>
  );
};

export { AppDrawer };
