import { Grid, Typography } from "@mui/material";
import { HhLimitation } from "../../../cobra-backend-client";
import { RssiZoneEnum } from "../../../models/dto/ws/monitoring/RssiZoneEnum";
import { HandheldButton } from "./HandheldButton";
import { HandheldLimitation } from "./HandheldLimitation";
import { HandheldSignal } from "./HandheldSignal";
import { HandheldZone } from "./HandheldZone";

interface Props {
  hhLimitation: HhLimitation | undefined;
  rssiZone: RssiZoneEnum | undefined;
  handheldButtons: boolean[] | undefined;
  handheldSignal: number | undefined;
}

const DeviceStatusHandheld = (props: Props) => {
  return (
    <Grid container sx={{ mb: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Handheld
        </Typography>
        <Grid container rowSpacing={1}>
          {props.hhLimitation != null && (
            <HandheldLimitation hhLimitation={props.hhLimitation} />
          )}

          {props.rssiZone != null && <HandheldZone rssiZone={props.rssiZone} />}
          {props.handheldButtons != null && (
            <HandheldButton handheldButtons={props.handheldButtons} />
          )}

          {props.handheldSignal != null && (
            <HandheldSignal handheldSignal={props.handheldSignal} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { DeviceStatusHandheld };
