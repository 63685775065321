export enum RssiZoneEnum {
  OUT_OF_ZONE = 0,
  WORK_ZONE = 1,
  SIDE_ZONE = 2,
  BACK_ZONE = 3,
  PL_ZONE = 4,
  ON_PL_ZONE = 5,
  INIT_ZONE = 6,
  UNKNOWN_ZONE = 7,
}

export const RssiZoneMessages: Record<RssiZoneEnum, string> = {
  [RssiZoneEnum.OUT_OF_ZONE]: "out of work-zone",
  [RssiZoneEnum.WORK_ZONE]: "work-zone",
  [RssiZoneEnum.SIDE_ZONE]: "side-zone",
  [RssiZoneEnum.BACK_ZONE]: "back-zone",
  [RssiZoneEnum.PL_ZONE]: "platform-zone",
  [RssiZoneEnum.ON_PL_ZONE]: "on-platform-zone",
  [RssiZoneEnum.INIT_ZONE]: "init-zone",
  [RssiZoneEnum.UNKNOWN_ZONE]: "unknown-zone",
};
