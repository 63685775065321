import { Slider, Typography } from "@mui/material";

interface Props {
  value: number | undefined;
  onChange: (newValue: number) => void;
  setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  min: number;
  max: number;
  step: number;
  captionLeft: string;
  captionRight: string;
  disabled?: boolean;
}

const CustomSlider = (props: Props) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      props.setValue(newValue);
      props.onChange(newValue);
    }
  };

  return (
    <>
      <Typography variant="caption">{props.captionLeft}</Typography>
      <Slider
        sx={{ mx: 1 }}
        size="small"
        valueLabelDisplay="auto"
        step={props.step}
        marks
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        onChange={handleChange}
        value={props.value}
      />
      <Typography variant="caption">{props.captionRight}</Typography>
    </>
  );
};

export { CustomSlider };
