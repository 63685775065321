import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COBRA_API } from "../../../api/config";
import { DEVICE_STATUS_INDEX } from "../../../constants/common";
import { LOGIN_PAGE } from "../../../constants/pages";

interface Props {
  deviceId: number;
  showAngleCalibrationProgress: boolean;
}

const DeviceRestart = (props: Props) => {
  const navigate = useNavigate();

  const [restartLoading, setRestartLoading] = useState(false);

  const onControllerRestartClick = async () => {
    setRestartLoading(true);

    await COBRA_API.DeviceMonitor.disableMonitoring(
      DEVICE_STATUS_INDEX.toString(),
      props.deviceId
    );

    try {
      const success = (
        await COBRA_API.DeviceRegistry.attemptDeviceRestart(props.deviceId)
      ).data;

      await COBRA_API.DeviceMonitor.enableMonitoring(
        DEVICE_STATUS_INDEX.toString(),
        props.deviceId
      );
      setRestartLoading(false);
    } catch (e) {
      setRestartLoading(false);
      navigate(LOGIN_PAGE);
    }
  };

  return (
    <>
      <Grid container flexGrow={1}>
        <Grid item xs={6} sm={6}>
          <Typography>Restart controller</Typography>
        </Grid>
        <Grid item xs={6} sm={6} textAlign="right">
          <Button
            disabled={props.showAngleCalibrationProgress}
            onClick={onControllerRestartClick}
          >
            <RestartAltIcon />
          </Button>
        </Grid>
      </Grid>
      <Dialog fullScreen open={restartLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ mt: 3 }}>
            Device Restart in Progress
          </Typography>
          <Typography sx={{ mb: 2, px: 3 }} variant="h6">
            The device is currently rebooting. This may take a moment. Thank you
            for your patience.
          </Typography>

          <CircularProgress color="primary" size={70} sx={{ mt: 1 }} />
        </Box>
      </Dialog>
    </>
  );
};

export { DeviceRestart };
