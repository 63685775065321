export enum PlatformSensorFootSwitchModeEnum {
  OFF = 0,
  MODE1 = 1,
  MODE2 = 2,
  MODE3 = 3,
}

const platformSensorFootSwitchModeLabels = {
  [PlatformSensorFootSwitchModeEnum.OFF]: "Mode off",
  [PlatformSensorFootSwitchModeEnum.MODE1]: "3-Buttons",
  [PlatformSensorFootSwitchModeEnum.MODE2]: "2-Buttons-Single-Click",
  [PlatformSensorFootSwitchModeEnum.MODE3]: "2-Buttons-Double-Click",
};

export const mapPlatformSensorFootSwitchModeValueToLabel = (
  orientation: PlatformSensorFootSwitchModeEnum | undefined
): string => {
  if (orientation == null) {
    return "Unknown";
  }

  return platformSensorFootSwitchModeLabels[orientation] || "Unknown";
};

export const getPlatformSensorFootSwitchModeEnumValues = () => {
  return Object.keys(PlatformSensorFootSwitchModeEnum).filter((item) => {
    return !isNaN(Number(item));
  });
};
