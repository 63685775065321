import { DeviceConfiguration } from "../cobra-backend-client";
import { RssiZoneVehicleTypeEnum } from "../models/RssiZoneVehicleTypeEnum";
import {
  get_POSITION_SENSOR_CONFIGURATION_SEMI_TRAILER_OVER_10M,
  get_POSITION_SENSOR_CONFIGURATION_SINGLE_POSITION_SENSOR,
  get_POSITION_SENSOR_CONFIGURATION_TRAILER_OVER_7_5M,
  get_POSITION_SENSOR_CONFIGURATION_TRAILER_UP_TO_7_5M,
  get_POSITION_SENSOR_CONFIGURATION_TRUCK_OVER_7_5M,
  get_POSITION_SENSOR_CONFIGURATION_TRUCK_UP_TO_7_5M,
} from "../predefined-configs/positionSensorConfigurations";

export const getPositionSensorConfiguration = (
  rssiZoneVehicleType: RssiZoneVehicleTypeEnum,
  deviceConfiguration: DeviceConfiguration
) => {
  switch (rssiZoneVehicleType) {
    case RssiZoneVehicleTypeEnum.SINGLE_POSITION_SENSOR:
      return get_POSITION_SENSOR_CONFIGURATION_SINGLE_POSITION_SENSOR(
        deviceConfiguration
      );
    case RssiZoneVehicleTypeEnum.TRUCK_UP_TO_7M5:
      return get_POSITION_SENSOR_CONFIGURATION_TRUCK_UP_TO_7_5M(
        deviceConfiguration
      );
    case RssiZoneVehicleTypeEnum.TRAILER_UP_TO_7M5:
      return get_POSITION_SENSOR_CONFIGURATION_TRAILER_UP_TO_7_5M(
        deviceConfiguration
      );
    case RssiZoneVehicleTypeEnum.TRUCK_OVER_7M5:
      return get_POSITION_SENSOR_CONFIGURATION_TRUCK_OVER_7_5M(
        deviceConfiguration
      );
    case RssiZoneVehicleTypeEnum.TRAILER_OVER_7M5:
      return get_POSITION_SENSOR_CONFIGURATION_TRAILER_OVER_7_5M(
        deviceConfiguration
      );
    case RssiZoneVehicleTypeEnum.SEMITRAILER_OVER_10M:
      return get_POSITION_SENSOR_CONFIGURATION_SEMI_TRAILER_OVER_10M(
        deviceConfiguration
      );
    default:
      return undefined;
  }
};
