import { ChangeEvent, useState } from "react";

import { useDispatch } from "react-redux";
import { COBRA_API } from "../../../api/config";
import {
  setCobraDeviceMcFwVersion,
  setCobraDeviceScFwVersion,
  setCobraPlSensorMcFwVersion,
  setCobraPlSensorScFwVersion,
} from "../../../stores/slices/firmwareVersionSlice";
import { getFirmwareDeviceMicroControllerVersions } from "../../../utils/firmwareDeviceMicroControllerVersions";
import { CustomAlert } from "../../common/CustomAlert";
import { CustomFileUploader } from "../../common/CustomFileUploader";
import { FirmwareUpdate } from "./FirmwareUpdate";

const CONFIGURATION_UPDATE_DEFAULT_VALUE = true;

const FirmwareUpdateContainer = () => {
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [isNewFirmwareInstalled, setIsNewFirmwareInstalled] =
    useState<boolean>(false);
  const [firmwareError, setFirmwareError] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [
    isConfigurationUpdatedSuccessfully,
    setIsConfigurationUpdatedSuccessfully,
  ] = useState(CONFIGURATION_UPDATE_DEFAULT_VALUE);
  const [
    skipConfigurationErrorsSwitchChecked,
    setSkipConfigurationErrorsSwitchChecked,
  ] = useState(false);

  const [firmwareUpdateInProgress, setFirmwareUpdateInProgress] =
    useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      getFirmwareVersion(file);
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(undefined);
    getFirmwareVersion();
  };

  const getFirmwareVersion = async (file?: File) => {
    try {
      const firmwareDeviceMicroControllerVersionDto = (
        await COBRA_API.FirmwareUpdate.retrieveFirmwareDeviceMicroControllerVersions(
          file
        )
      ).data;

      const {
        deviceMcFwVersion,
        deviceScFwVersion,
        plSensorMcFwVersion,
        plSensorScFwVersion,
      } = getFirmwareDeviceMicroControllerVersions(
        firmwareDeviceMicroControllerVersionDto
      );

      dispatch(setCobraDeviceMcFwVersion(deviceMcFwVersion));
      dispatch(setCobraDeviceScFwVersion(deviceScFwVersion));
      dispatch(setCobraPlSensorMcFwVersion(plSensorMcFwVersion));
      dispatch(setCobraPlSensorScFwVersion(plSensorScFwVersion));
    } catch (e: any) {}
  };

  return (
    <>
      <CustomFileUploader
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
        handleDeleteFile={handleDeleteFile}
        buttonText="Select File"
        buttonTooltipText="Select new firmware"
        fileFormat={".zip"}
      />

      <FirmwareUpdate
        selectedFile={selectedFile}
        setFirmwareError={setFirmwareError}
        setIsNewFirmwareInstalled={setIsNewFirmwareInstalled}
        setAlertMessage={setAlertMessage}
        setShowAlert={setShowAlert}
        isConfigurationUpdatedSuccessfully={isConfigurationUpdatedSuccessfully}
        setIsConfigurationUpdatedSuccessfully={
          setIsConfigurationUpdatedSuccessfully
        }
        setFirmwareUpdateInProgress={setFirmwareUpdateInProgress}
        firmwareUpdateInProgress={firmwareUpdateInProgress}
        skipConfigurationErrorsSwitchChecked={
          skipConfigurationErrorsSwitchChecked
        }
        setSkipConfigurationErrorsSwitchChecked={
          setSkipConfigurationErrorsSwitchChecked
        }
      />

      {showAlert && (
        <CustomAlert
          autoHideDurationMs={12000}
          onAlertClose={() => setShowAlert(false)}
          alertSeverity={isNewFirmwareInstalled ? "success" : "error"}
          alertText={alertMessage}
          alertAdditionalInfo={!isNewFirmwareInstalled ? firmwareError : ""}
        />
      )}
    </>
  );
};

export { FirmwareUpdateContainer };
