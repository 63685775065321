import { Chip, Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const AutomaticTiltDown = (props: Props) => {
  const [automaticTiltDown, setAutomaticTiltDown] = useState(
    props.updatedDeviceConfiguration.automaticTilt?.down?.enable
  );

  useEffect(() => {
    setAutomaticTiltDown(
      props.updatedDeviceConfiguration.automaticTilt?.down?.enable
    );
  }, [props.updatedDeviceConfiguration.automaticTilt?.down?.enable]);

  const onPlatformSensorBlinkModeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAutomaticTiltDown(checked);
    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      automaticTilt: {
        ...props.updatedDeviceConfiguration.automaticTilt,
        down: {
          ...props.updatedDeviceConfiguration.automaticTilt.down,
          enable: checked,
        },
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Autotilt down </Typography>
        <Chip
          sx={{ ml: 1 }}
          variant="outlined"
          label={automaticTiltDown ? "on" : "off"}
          color={automaticTiltDown ? "success" : "error"}
          size="small"
        />
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={automaticTiltDown}
          onChange={onPlatformSensorBlinkModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
    </>
  );
};

export { AutomaticTiltDown };
