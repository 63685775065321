import MenuBookIcon from "@mui/icons-material/MenuBook";
import { CustomDrawerMenuItem } from "./CustomDrawerMenuItem";

const SCHMIDIGER_MANUAL_LINK = "https://cobra390.schmidiger.ch/manual";

export const onlineDeviceOrAuthenticatedDrawerMenuItems: CustomDrawerMenuItem[] = [
  {
    text: "Manual",
    icon: <MenuBookIcon />,
    link: SCHMIDIGER_MANUAL_LINK,
    protected: false,
    isExternakLink: true,
  },
];
