import { useEffect, useState } from "react";
import { useStompClient } from "react-stomp-hooks";
import {
  CustomStompCallback,
  CustomStompSubscription,
} from "../models/stomp/CustomStompModels";

const useCustomSubscriptionManager = () => {
  const stompClient = useStompClient();
  const [subscriptions, setSubscriptions] = useState<CustomStompSubscription[]>(
    []
  );
  const subscribeToTopic = (topic: string, callback: CustomStompCallback) => {
    if (stompClient?.connected) {
      const subscription = stompClient.subscribe(topic, callback);

      const customSubscription = { topic, subscription, callback };

      setSubscriptions((prevSubscriptions) => {
        const prevSubs = prevSubscriptions.filter(
          (s) => s.topic !== customSubscription.topic
        );
        return [...prevSubs, customSubscription];
      });
      return true;
    }
    return false;
  };

  const unsubscribeFromTopic = (topic: string) => {
    const subscription = subscriptions.find((s) => s.topic === topic);
    if (subscription) {
      stompClient?.unsubscribe(subscription?.subscription.id);
    }

    const updatedSubscriptions = subscriptions.filter(
      (sub) => sub.topic !== topic
    );
    setSubscriptions(updatedSubscriptions);
  };

  useEffect(() => {
    return () => {
      // Unsubscribe from all subscriptions when the component unmounts
      subscriptions.forEach((sub) => {
        sub.subscription.unsubscribe();
      });
    };
  }, []);

  return {
    subscribeToTopic,
    unsubscribeFromTopic,
    isStompClientConnected: stompClient?.connected,
  };
};

export { useCustomSubscriptionManager };
