import { RxPlsVersion } from "../cobra-backend-client";
import {
  VERSION_HARDWARE,
  VERSION_MC_BOOTLOADER,
  VERSION_MC_FIRMWARE,
  VERSION_SC_BOOTLOADER,
  VERSION_SC_FIRMWARE,
  VERSION_SERIAL_NUMBER,
} from "../constants/displayProperties";

export const DEVICE_UNKNOWN_VERSION = "-1.-1.-1";

export const displayDeviceVersion = (version: string) => {
  return version == DEVICE_UNKNOWN_VERSION ? "unknown" : version;
};

export const createRxVersionDisplayPropertyValueMap = (
  deviceVersion: RxPlsVersion | undefined
) => {
  const rxVersionDisplayPropertyValueMap = new Map<string, string>();

  rxVersionDisplayPropertyValueMap.set(
    VERSION_SERIAL_NUMBER,
    displayDeviceVersion(deviceVersion?.rx?.serial?.toString() ?? "")
  );
  rxVersionDisplayPropertyValueMap.set(
    VERSION_HARDWARE,
    displayDeviceVersion(deviceVersion?.rx?.hw?.toString() ?? "")
  );
  rxVersionDisplayPropertyValueMap.set(
    VERSION_MC_BOOTLOADER,
    displayDeviceVersion(deviceVersion?.rx?.mcBl?.toString() ?? "")
  );
  rxVersionDisplayPropertyValueMap.set(
    VERSION_MC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.rx?.mcFw?.toString() ?? "")
  );
  rxVersionDisplayPropertyValueMap.set(
    VERSION_SC_BOOTLOADER,
    displayDeviceVersion(deviceVersion?.rx?.scBl?.toString() ?? "")
  );
  rxVersionDisplayPropertyValueMap.set(
    VERSION_SC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.rx?.scFw?.toString() ?? "")
  );

  return rxVersionDisplayPropertyValueMap;
};

// If serial number is max value, the pls does not exist.
export const PLATFORM_SENSOR_SERIAL_NUMBER_MAX_VALUE = 4294967295;

export const createPlsVersionDisplayPropertyValueMap = (
  deviceVersion: RxPlsVersion | undefined
) => {
  const plsVersionDisplayPropertyValueMap = new Map<string, string>();
  if (
    !deviceVersion?.pls?.serial ||
    deviceVersion?.pls.serial === PLATFORM_SENSOR_SERIAL_NUMBER_MAX_VALUE
  ) {
    return plsVersionDisplayPropertyValueMap;
  }

  plsVersionDisplayPropertyValueMap.set(
    VERSION_SERIAL_NUMBER,
    displayDeviceVersion(deviceVersion?.pls?.serial?.toString() ?? "")
  );
  plsVersionDisplayPropertyValueMap.set(
    VERSION_HARDWARE,
    displayDeviceVersion(deviceVersion?.pls?.hw?.toString() ?? "")
  );
  plsVersionDisplayPropertyValueMap.set(
    VERSION_MC_BOOTLOADER,
    displayDeviceVersion(deviceVersion?.pls?.mcBl?.toString() ?? "")
  );
  plsVersionDisplayPropertyValueMap.set(
    VERSION_MC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.pls?.mcFw?.toString() ?? "")
  );
  plsVersionDisplayPropertyValueMap.set(
    VERSION_SC_BOOTLOADER,
    displayDeviceVersion(deviceVersion?.pls?.scBl?.toString() ?? "")
  );
  plsVersionDisplayPropertyValueMap.set(
    VERSION_SC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.pls?.scFw?.toString() ?? "")
  );

  return plsVersionDisplayPropertyValueMap;
};

export const createRxFirmwareVersionDisplayPropertyValueMap = (
  deviceVersion: RxPlsVersion | undefined
) => {
  const rxFirmwareVersionDisplayPropertyValueMap = new Map<string, string>();

  rxFirmwareVersionDisplayPropertyValueMap.set(
    VERSION_MC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.rx?.mcFw?.toString() ?? "")
  );
  rxFirmwareVersionDisplayPropertyValueMap.set(
    VERSION_SC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.rx?.scFw?.toString() ?? "")
  );

  return rxFirmwareVersionDisplayPropertyValueMap;
};

export const createPlsFirmwareVersionDisplayPropertyValueMap = (
  deviceVersion: RxPlsVersion | undefined
) => {
  const plsFirmwareVersionDisplayPropertyValueMap = new Map<string, string>();
  if (
    !deviceVersion?.pls?.serial ||
    deviceVersion?.pls.serial === PLATFORM_SENSOR_SERIAL_NUMBER_MAX_VALUE
  ) {
    return plsFirmwareVersionDisplayPropertyValueMap;
  }

  plsFirmwareVersionDisplayPropertyValueMap.set(
    VERSION_MC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.pls?.mcFw?.toString() ?? "")
  );
  plsFirmwareVersionDisplayPropertyValueMap.set(
    VERSION_SC_FIRMWARE,
    displayDeviceVersion(deviceVersion?.pls?.scFw?.toString() ?? "")
  );

  return plsFirmwareVersionDisplayPropertyValueMap;
};
