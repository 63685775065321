import { Fragment } from "react";

import shiftButtonSvg from "../assets/Shift.svg";
import buttonCloseSvg from "../assets/btnClose.svg";
import buttonDownSvg from "../assets/btnDown.svg";
import buttonOpenSvg from "../assets/btnOpen.svg";
import buttonPlatformInSvg from "../assets/btnPlatformIn.svg";
import buttonPlatformOutSvg from "../assets/btnPlatformOut.svg";
import buttonTrailerSvg from "../assets/btnTrailer.svg";
import buttonTruckSvg from "../assets/btnTruck.svg";
import buttonUpSvg from "../assets/btnUp.svg";


const orderedByIndexIcons = [
  buttonCloseSvg,
  buttonUpSvg,
  buttonOpenSvg,
  buttonDownSvg,
  buttonPlatformInSvg,
  buttonPlatformOutSvg,
  buttonTruckSvg,
  buttonTrailerSvg,
];

export const renderOrderByIndexSvgButtonsComponent = (svgIndex: number) => {
  if (svgIndex >= 0 && svgIndex < 8) {
    const icon = orderedByIndexIcons[svgIndex];

    return (
      <Fragment key={`BTN_${svgIndex}_${new Date().getTime()}`}>
        <img src={icon} style={{ width: "30px", height: "30px" }} />
      </Fragment>
    );
  } else if (svgIndex >= 8 && svgIndex < 16) {
    const shiftIndex = svgIndex - 8;
    const icon = orderedByIndexIcons[shiftIndex];

    return (
      <Fragment key={`SHIFT_BTN_${svgIndex}_${new Date().getTime()}`}>
        <img src={shiftButtonSvg} style={{ width: "30px", height: "30px" }} />
        <img src={icon} style={{ width: "30px", height: "30px" }} />
      </Fragment>
    );
  }
  return <></>;
};

