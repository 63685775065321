import { Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as NoDownSvg } from "../../../assets/noDown.svg";
import { ReactComponent as NoDownGraySvg } from "../../../assets/noDown_Grau.svg";
import { ReactComponent as NoUpSvg } from "../../../assets/noUp.svg";
import { ReactComponent as NoUpDownSvg } from "../../../assets/noUpDown.svg";
import { ReactComponent as NoUpDownGraySvg } from "../../../assets/noUpDown_Grau.svg";
import { ReactComponent as NoUpGraySvg } from "../../../assets/noUp_Grau.svg";
import { HhLimitation } from "../../../cobra-backend-client";

interface Props {
  hhLimitation: HhLimitation;
}

const HandheldLimitation = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Handheld limitation </Typography>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {props.hhLimitation.noUpDown ? (
            <NoUpDownSvg
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          ) : (
            <NoUpDownGraySvg
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          )}

          {props.hhLimitation.noClose ? (
            <NoUpSvg
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          ) : (
            <NoUpGraySvg
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          )}

          {props.hhLimitation.noOpen ? (
            <NoDownSvg
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          ) : (
            <NoDownGraySvg
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          )}
        </Stack>
      </Grid>
    </>
  );
};

export { HandheldLimitation };
