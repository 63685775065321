import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RxPlsVersion } from "../../cobra-backend-client";

interface DeviceState {
  deviceId: string | undefined;
  deviceType: string | undefined;
  projectId: string | undefined;
  manufacturerId: string | undefined;
  isDeviceOnline: boolean | undefined;
  deviceVersion: RxPlsVersion | undefined;
}

const initialState: DeviceState = {
  deviceId: undefined,
  deviceType: undefined,
  projectId: undefined,
  manufacturerId: undefined,
  isDeviceOnline: false,
  deviceVersion: undefined,
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setDeviceId: (state, action: PayloadAction<string | undefined>) => {
      state.deviceId = action.payload;
    },
    setDeviceType: (state, action: PayloadAction<string | undefined>) => {
      state.deviceType = action.payload;
    },
    setProjectId: (state, action: PayloadAction<string | undefined>) => {
      state.projectId = action.payload;
    },
    setManufacturerId: (state, action: PayloadAction<string | undefined>) => {
      state.manufacturerId = action.payload;
    },
    setIsDeviceOnline: (state, action: PayloadAction<boolean>) => {
      state.isDeviceOnline = action.payload;
    },
    setDeviceVersion: (state, action: PayloadAction<RxPlsVersion | undefined>) => {
      state.deviceVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDeviceSlice, () => initialState);
  },
});

export const clearDeviceSlice = createAction("clearDeviceSlice");

export const {
  setDeviceId,
  setDeviceType,
  setIsDeviceOnline,
  setDeviceVersion,
  setProjectId,
  setManufacturerId,
} = deviceSlice.actions;
export default deviceSlice.reducer;
