import { JwtPayload, jwtDecode } from "jwt-decode";
import { UserTokenInformation } from "../models/UserTokenInformation";

export const isJwtTokenExpired = (
  token: string | null,
  secondsBeforeExpiration = 60
) => {
  if (!token) return true;
  try {
    const decoded: JwtPayload = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    const expirationTime = decoded.exp as number;
    const expirationThreshold = expirationTime - secondsBeforeExpiration;

    return currentTime >= expirationThreshold;
  } catch (error) {
    return true;
  }
};

export const decodeJwtToken = (
  token: string | null
): UserTokenInformation | null => {
  if (!token) return null;
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};
