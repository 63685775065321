import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { MainWithDrawer } from "../components/layout/MainWithDrawer";
import {
  CONTROLLER_INSTALLATION_PAGE,
  DEVICE_CONFIGURATION_PAGE,
  DEVICE_CONFIGURATION_SETTINGS_PAGE,
  DEVICE_FIRMWARE_UPDATE_PAGE,
  DEVICE_STATUS_PAGE,
  DEVICE_VERSION_PAGE,
  LOGIN_PAGE,
  SIGN_IN_PAGE,
} from "../constants/pages";
import { ConnectToDevicePage } from "../pages/ConnectToDevicePage";
import { ControllerInstallationPage } from "../pages/ControllerInstallationPage";
import { DeviceConfigurationPage } from "../pages/DeviceConfigurationPage";
import { DeviceConfigurationSettingsPage } from "../pages/DeviceConfigurationSettingsPage";
import { DeviceStatusPage } from "../pages/DeviceStatusPage";
import { FirmwareUpdatePage } from "../pages/FirmwareUpdatePage";
import { SignInPage } from "../pages/SignInPage";
import { PrivateOutlet } from "./PrivateOutlet";
import { DeviceVersionPage } from "../pages/DeviceVersionPage";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainWithDrawer />}>
          <Route path={LOGIN_PAGE} element={<ConnectToDevicePage />}></Route>
          <Route path={DEVICE_STATUS_PAGE} element={<DeviceStatusPage />} />
          <Route
            path={DEVICE_CONFIGURATION_PAGE}
            element={
              <PrivateOutlet>
                <DeviceConfigurationPage />
              </PrivateOutlet>
            }
          ></Route>
          <Route
            path={DEVICE_FIRMWARE_UPDATE_PAGE}
            element={
              <PrivateOutlet>
                <FirmwareUpdatePage />
              </PrivateOutlet>
            }
          ></Route>
          <Route
            path={CONTROLLER_INSTALLATION_PAGE}
            element={
              <PrivateOutlet>
                <ControllerInstallationPage />
              </PrivateOutlet>
            }
          ></Route>
          <Route
            path={DEVICE_CONFIGURATION_SETTINGS_PAGE}
            element={
              <PrivateOutlet>
                <DeviceConfigurationSettingsPage />
              </PrivateOutlet>
            }
          ></Route>
          <Route
            path={DEVICE_VERSION_PAGE}
            element={
              <PrivateOutlet>
                <DeviceVersionPage />
              </PrivateOutlet>
            }
          ></Route>
        </Route>
        <Route path={SIGN_IN_PAGE} element={<SignInPage />}></Route>
        <Route path="/" element={<SignInPage />}></Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
