import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeviceStatusContainer } from "../components/device/device-status/DeviceStatusContainer";
import { LOGIN_PAGE } from "../constants/pages";
import { clearDeviceSlice } from "../stores/slices/deviceSlice";
import { RootState } from "../stores/store";
import { Container, LinearProgress, useMediaQuery } from "@mui/material";
import { customizedTheme } from "../theme/theme";

const STOMP_CLIENT_MAX_CONNECTION_TIME = 6500;

interface Props {}

const DeviceStatusPage = memo(function DeviceStatusPage(props: Props) {
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );
  const isDeviceOnline = useSelector(
    (state: RootState) => state.device.isDeviceOnline
  );
  const isStompConnected = useSelector(
    (state: RootState) => state.stomp.isStompConnected
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stompClientLoading, setStompClientLoading] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!isStompConnected) {
      timeoutId = setTimeout(() => {
        setStompClientLoading(false);
      }, STOMP_CLIENT_MAX_CONNECTION_TIME);
    } else {
      setStompClientLoading(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isStompConnected]);

  useEffect(() => {
    if (!isDeviceOnline) {
      dispatch(clearDeviceSlice());
      navigate(LOGIN_PAGE, { replace: true });
    }
  }, [isDeviceOnline]);

  return (
    <Container
      maxWidth={isScreenSizeGreaterThanSm ? "sm" : false}
      disableGutters
    >
      {stompClientLoading ? <LinearProgress /> : <DeviceStatusContainer />}
    </Container>
  );
});

export { DeviceStatusPage };
