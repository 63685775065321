export const DEVICE_STATUS_INDEX = 0;

export const HTTP_STATUS_CONFLICT = 409;
export const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;
export const HTTP_STATUS_BAD_REQUEST= 400;

export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX = 0;
export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_2_INDEX = 1;
export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_3_INDEX = 2;
export const OUT_ALLOCATION_FLASHLIGHT_ANGLE_4_INDEX = 3;
