import { Fragment } from "react";
import {
  DeviceConfiguration,
  OutputConfig,
} from "../../../cobra-backend-client";
import { Grid, Switch, Typography } from "@mui/material";
import { OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX } from "../../../constants/common";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}
const OutAngleSectors = (props: Props) => {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    index: number
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    if (
      newOutputConfigTable[props.outAllocationIndex]?.angleSectors?.[index] !=
      null
    ) {
      newOutputConfigTable[props.outAllocationIndex].angleSectors![index] =
        checked;
    }

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      {props.updatedDeviceConfiguration.out?.outputConfigTable?.[
        props.outAllocationIndex
      ].angleSectors?.map((isAngleSelected, index) => (
        <Fragment key={`angle_${index}`}>
          <Grid
            item
            xs={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <Typography sx={{ mr: 1, whiteSpace: "nowrap" }}>
              {index === OUT_ALLOCATION_FLASHLIGHT_ANGLE_INDEX && `Flashlight `}
              Angle {index + 1}
            </Typography>

            <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
              {`${props.updatedDeviceConfiguration.angleSector?.minDeg?.[index]}°..`}
              {` ${props.updatedDeviceConfiguration.angleSector?.maxDeg?.[index]}°`}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Switch
              checked={isAngleSelected}
              onChange={(e, checked) => onChange(e, checked, index)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Fragment>
      ))}
    </>
  );
};

export { OutAngleSectors };
