import { Container, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { COBRA_API } from "../api/config";
import { VariokeyItem } from "../cobra-backend-client";
import { DeviceConfigWrapper } from "../components/common/DeviceConfigurationWrapper";
import { DeviceConfigurationSettingsContainer } from "../components/device/configuration-settings/DeviceConfigurationSettingsContainer";
import { customizedTheme } from "../theme/theme";

interface Props {}

const DeviceConfigurationSettingsPage = (props: Props) => {
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );

  const [variokeys, setVariokeys] = useState<VariokeyItem[]>([]);

  useEffect(() => {
    getVariokeys();
  }, []);

  const getVariokeys = async () => {
    try {
      const variokeys = (await COBRA_API.DeviceConfiguration.getVariokeyList())
        .data;
      setVariokeys(variokeys.variokeys ?? []);
    } catch (e) {}
  };

  return (
    <DeviceConfigWrapper>
      {(deviceConfiguration) => (
        <Container
          maxWidth={isScreenSizeGreaterThanSm ? "sm" : false}
          disableGutters
        >
          <DeviceConfigurationSettingsContainer
            deviceConfiguration={deviceConfiguration}
            variokeys={variokeys}
          />
        </Container>
      )}
    </DeviceConfigWrapper>
  );
};

export { DeviceConfigurationSettingsPage };
