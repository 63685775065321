import axios from "axios";
import {
  Configuration,
  DeviceConfigurationControllerApiFactory,
  DeviceMonitoringControllerApiFactory,
  DeviceRegistryControllerApiFactory,
  FirmwareUpdateControllerApiFactory,
  JwtTokenControllerApiFactory,
} from "../cobra-backend-client";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthorizationHeader = (token: string) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

export const COBRA_API = {
  JwtToken: JwtTokenControllerApiFactory(
    new Configuration(),
    "",
    axiosInstance
  ),
  FirmwareUpdate: FirmwareUpdateControllerApiFactory(
    new Configuration(),
    "",
    axiosInstance
  ),
  DeviceMonitor: DeviceMonitoringControllerApiFactory(
    new Configuration(),
    "",
    axiosInstance
  ),
  DeviceConfiguration: DeviceConfigurationControllerApiFactory(
    new Configuration(),
    "",
    axiosInstance
  ),
  DeviceRegistry: DeviceRegistryControllerApiFactory(
    new Configuration(),
    "",
    axiosInstance
  ),
};

export { axiosInstance };
