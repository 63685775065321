export enum PlatformSensorOrientationEnum {
  UP = 0,
  DOWN = 1,
  RIGHT = 2,
  LEFT = 3,
}

const platformSensorOrientationLabels = {
  [PlatformSensorOrientationEnum.UP]: "Up",
  [PlatformSensorOrientationEnum.DOWN]: "Down",
  [PlatformSensorOrientationEnum.RIGHT]: "Right",
  [PlatformSensorOrientationEnum.LEFT]: "Left",
};

export const mapPlatformSensorOrientationValueToLabel = (
  orientation: PlatformSensorOrientationEnum | undefined
): string => {
  if (orientation == null) {
    return "Unknown";
  }

  return platformSensorOrientationLabels[orientation] || "Unknown";
};

export const getPlatformSensorOrientationEnumValues = () => {
  return Object.keys(PlatformSensorOrientationEnum).filter((item) => {
    return !isNaN(Number(item));
  });
};

export const getPlatformSensorOrientationEnumKeys = () => {
  return Object.keys(PlatformSensorOrientationEnum).filter((item) => {
    return isNaN(Number(item));
  });
};
