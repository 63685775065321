import EditIcon from "@mui/icons-material/Edit";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { CustomFab } from "../../common/CustomFab";
import { OutAllocationDialog } from "./OutAllocationDialog";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}

const OutAllocation = (props: Props) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Grid
        key={`OUT ${props.outAllocationIndex + 1}`}
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>{`OUT ${props.outAllocationIndex + 1}`} </Typography>
      </Grid>
      <Grid item xs={6} textAlign="right">
        <CustomFab icon={<EditIcon />} onClick={() => setOpenDialog(true)} />
      </Grid>
      <OutAllocationDialog
        updatedDeviceConfiguration={props.updatedDeviceConfiguration}
        setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
        openDialog={openDialog}
        onDialogClose={() => setOpenDialog(false)}
        dialogTitle={`OUT ${props.outAllocationIndex + 1}`}
        outAllocationIndex={props.outAllocationIndex}
      />
    </>
  );
};

export { OutAllocation };
