import { Box, Grid, Typography } from "@mui/material";
import { ReadOnlyCheckBox } from "../../common/ReadOnlyCheckBox";
import { OutputA, OutputB } from "../../../cobra-backend-client";

interface Props {
  outputA: OutputA;
  outputB: OutputB;
}

const OutOutput = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={2}
        sm={4}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Out 1..8 </Typography>
      </Grid>
      <Grid
        item
        xs={10}
        sm={8}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box component="span" sx={{ pr: 1.5 }}>
          <ReadOnlyCheckBox checked={props.outputA.output1Activated ?? false} />
          <ReadOnlyCheckBox checked={props.outputA.output2Activated ?? false} />
          <ReadOnlyCheckBox checked={props.outputA.output3Activated ?? false} />
          <ReadOnlyCheckBox checked={props.outputA.output4Activated ?? false} />
        </Box>
        <Box component="span">
          <ReadOnlyCheckBox checked={props.outputB.output5Activated ?? false} />
          <ReadOnlyCheckBox checked={props.outputB.output6Activated ?? false} />
          <ReadOnlyCheckBox checked={props.outputB.output7Activated ?? false} />
          <ReadOnlyCheckBox checked={props.outputB.output8Activated ?? false} />
        </Box>
      </Grid>
    </>
  );
};

export { OutOutput };
