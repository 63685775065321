import { Chip, Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as CabinSwitchIcon } from "../../../assets/Fahrerhausschalter.svg";
import { DeviceConfiguration } from "../../../cobra-backend-client";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const CabinSwitchEnabled = (props: Props) => {
  const [cabinSwitchValue, setCabinSwitchValue] = useState(
    props.updatedDeviceConfiguration.out?.fheConf
  );
  useEffect(() => {
    setCabinSwitchValue(props.updatedDeviceConfiguration.out?.fheConf);
  }, [props.updatedDeviceConfiguration.out?.fheConf]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newCabinSwitchValue = checked ? 1 : 0;
    setCabinSwitchValue(newCabinSwitchValue);

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        fheConf: newCabinSwitchValue,
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>
          <CabinSwitchIcon
            style={{ width: "24px", height: "24px", marginRight: "10px" }}
          />
          IN 6, Cabin Switch
        </Typography>
        <Chip
          sx={{ ml: 1 }}
          variant="outlined"
          label={cabinSwitchValue === 1 ? "on" : "off"}
          color={cabinSwitchValue === 1 ? "success" : "error"}
          size="small"
        />
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={cabinSwitchValue === 1}
          onChange={onChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
    </>
  );
};

export { CabinSwitchEnabled };
