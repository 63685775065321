import { useSelector } from "react-redux";
import {
  ExternalListItemButton,
  InternalListItemButton,
} from "./MenuListItemButton";
import { RootState } from "../../stores/store";
import { onlineDeviceOrAuthenticatedDrawerMenuItems } from "../../routes/onlineDeviceOrAuthenticatedDrawerMenuItems";

interface Props {
  isDeviceOnline: boolean | undefined;
}

const AppDrawerOnlineDeviceOrAuthenticatedMenuItem = (props: Props) => {
  const userToken = useSelector((state: RootState) => state.user.token);
  return (
    <>
      {(props.isDeviceOnline || !!userToken) &&
        onlineDeviceOrAuthenticatedDrawerMenuItems.map((item) =>
          item.isExternakLink ? (
            <ExternalListItemButton key={item.text} item={item} />
          ) : (
            <InternalListItemButton key={item.text} item={item} />
          )
        )}
    </>
  );
};

export { AppDrawerOnlineDeviceOrAuthenticatedMenuItem };
