import { Chip, Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  platformSensorActive: boolean | undefined;
}

const PlatformSensorBlinkModeEnabled = (props: Props) => {
  const [platformSensorOnModeEnabled, setPlatformSensorOnModeEnabled] =
    useState<boolean>(
      props.updatedDeviceConfiguration.psio?.outBlinkOffTime === 0
    );

  useEffect(() => {
    const blinkOnModeEnabled =
      props.updatedDeviceConfiguration.psio?.outBlinkOffTime === 0;

    setPlatformSensorOnModeEnabled(blinkOnModeEnabled);
  }, [props.updatedDeviceConfiguration.psio?.outBlinkOffTime]);

  const onPlatformSensorBlinkModeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPlatformSensorOnModeEnabled(checked);
    let newDeviceConfiguration: DeviceConfiguration;
    if (checked) {
      newDeviceConfiguration = {
        ...props.updatedDeviceConfiguration,
        psio: {
          ...props.updatedDeviceConfiguration.psio,
          outBlinkOffTime: 0,
        },
      };
    } else {
      newDeviceConfiguration = {
        ...props.updatedDeviceConfiguration,
        psio: {
          ...props.updatedDeviceConfiguration.psio,
          outBlinkOnTime: 50,
          outBlinkOffTime: 50,
        },
      };
    }

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Platform Sensor blink output</Typography>
        <Chip
          sx={{ ml: 1 }}
          variant="outlined"
          label={platformSensorOnModeEnabled ? "Always on" : "BLINK"}
          color={platformSensorOnModeEnabled ? "success" : "error"}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Switch
          checked={platformSensorOnModeEnabled}
          onChange={onPlatformSensorBlinkModeChange}
          inputProps={{ "aria-label": "controlled" }}
          disabled={!props.platformSensorActive}
        />
      </Grid>
    </>
  );
};

export { PlatformSensorBlinkModeEnabled };
