export enum RssiZoneVehicleTypeEnum {
  CUSTOM_CONFIGURATION = 0,
  SINGLE_POSITION_SENSOR = 1,
  TRUCK_UP_TO_7M5 = 2,
  TRAILER_UP_TO_7M5 = 3,
  TRUCK_OVER_7M5 = 4,
  TRAILER_OVER_7M5 = 5,
  SEMITRAILER_OVER_10M = 6,
}

export const rssiZoneEnumDisplayValues = {
  [RssiZoneVehicleTypeEnum.CUSTOM_CONFIGURATION]: "Custom Configuration",
  [RssiZoneVehicleTypeEnum.SINGLE_POSITION_SENSOR]: "Single position sensor",
  [RssiZoneVehicleTypeEnum.TRUCK_UP_TO_7M5]: "Truck up to 7.5m",
  [RssiZoneVehicleTypeEnum.TRAILER_UP_TO_7M5]: "Trailer up to 7.5m",
  [RssiZoneVehicleTypeEnum.TRUCK_OVER_7M5]: "Truck over 7.5m",
  [RssiZoneVehicleTypeEnum.TRAILER_OVER_7M5]: "Trailer over 7.5m",
  [RssiZoneVehicleTypeEnum.SEMITRAILER_OVER_10M]: "Semitrailer over 10m",
};

export const mapRssiZoneVehicleTypeEnumValueToDisplayValue = (
  rssiZoneVehicleType: RssiZoneVehicleTypeEnum | undefined
): string => {
  if (rssiZoneVehicleType == null) {
    return "Unknown";
  }

  return rssiZoneEnumDisplayValues[rssiZoneVehicleType] || "Unknown";
};

export const getRssiZoneVehicleTypeEnumValues = () => {
  return Object.keys(RssiZoneVehicleTypeEnum)
    .filter((item) => {
      return !isNaN(Number(item));
    })
    .map((value) => +value);
};
