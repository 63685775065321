import { Grid, Switch } from "@mui/material";
import {
  DeviceConfiguration,
  OutputConfig,
} from "../../../cobra-backend-client";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}

const OutVred = (props: Props) => {
  const onReductionValveChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    newOutputConfigTable[props.outAllocationIndex].vred = checked;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  const onVredInvertedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      vred: {
        ...props.updatedDeviceConfiguration.vred,
        inverted: checked,
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  const onFastIfSideZoneEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      vred: {
        ...props.updatedDeviceConfiguration.vred,
        fastIfSideZoneEnable: checked,
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        Reduction Valve
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={
            props.updatedDeviceConfiguration.out?.outputConfigTable?.[
              props.outAllocationIndex
            ].vred
          }
          onChange={onReductionValveChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>

      {props.updatedDeviceConfiguration.out?.outputConfigTable?.[
        props.outAllocationIndex
      ].vred && (
        <>
          <Grid
            item
            xs={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            Valve Type: Reduction Active Low
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Switch
              checked={props.updatedDeviceConfiguration.vred?.inverted}
              onChange={onVredInvertedChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            fast in save zone, +/- 10°
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Switch
              checked={
                props.updatedDeviceConfiguration.vred?.fastIfSideZoneEnable
              }
              onChange={onFastIfSideZoneEnabledChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export { OutVred };
