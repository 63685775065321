import "./AngleViewer.css";

interface Props {
  minValue: number;
  maxValue: number;
}

const AngleViewer = (props: Props) => {
  const transformStyleMinValue = {
    transform: `rotate(${-props.minValue}deg)`,
  };

  const transformStyleMaxValue = {
    transform: `rotate(${-props.maxValue}deg)`,
  };

  return (
    <div className="main-container">
      <div className="circle-container">
        <div className="lines zero-line">
          <div className="label">0°</div>
        </div>

        <div
          className="lines line-min"
          style={{
            ...transformStyleMinValue,
          }}
        >
          <div className="label label-min">{props.minValue}°</div>
        </div>

        <div
          className="lines line-max"
          style={{
            ...transformStyleMaxValue,
          }}
        >
          {/* <div className="custom">
                <div className="inner"></div>
              </div> */}

          <div className="label label-max">{props.maxValue}°</div>
        </div>
      </div>
    </div>
  );
};

export { AngleViewer };
