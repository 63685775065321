import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StompState {
  isStompConnected: boolean;
}

const initialState: StompState = {
    isStompConnected: false,
};

const drawerSlice = createSlice({
  name: "stomp",
  initialState,
  reducers: {
    setIsStompConnected: (state, action: PayloadAction<boolean>) => {
      state.isStompConnected = action.payload;
    },
  },
});

export const { setIsStompConnected } = drawerSlice.actions;

export default drawerSlice.reducer;
