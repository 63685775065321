import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FirmwareVersionState {
  cobraDeviceMcFwVersion: string | undefined;
  cobraDeviceScFwVersion: string | undefined;
  cobraPlSensorMcFwVersion: string | undefined;
  cobraPlSensorScFwVersion: string | undefined;
}

const initialState: FirmwareVersionState = {
  cobraDeviceMcFwVersion: undefined,
  cobraDeviceScFwVersion: undefined,
  cobraPlSensorMcFwVersion: undefined,
  cobraPlSensorScFwVersion: undefined,
};

const firmwareVersionSlice = createSlice({
  name: "firmwareVersion",
  initialState,
  reducers: {
    setCobraDeviceMcFwVersion: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.cobraDeviceMcFwVersion = action.payload;
    },
    setCobraDeviceScFwVersion: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.cobraDeviceScFwVersion = action.payload;
    },
    setCobraPlSensorMcFwVersion: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.cobraPlSensorMcFwVersion = action.payload;
    },
    setCobraPlSensorScFwVersion: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.cobraPlSensorScFwVersion = action.payload;
    },
    clearFirmwareVersions: (state) => {
      state.cobraDeviceMcFwVersion = undefined;
      state.cobraDeviceScFwVersion = undefined;
      state.cobraPlSensorMcFwVersion = undefined;
      state.cobraPlSensorScFwVersion = undefined;
    },
  },
});

export const {
  setCobraDeviceMcFwVersion,
  setCobraDeviceScFwVersion,
  setCobraPlSensorMcFwVersion,
  setCobraPlSensorScFwVersion,
  clearFirmwareVersions,
} = firmwareVersionSlice.actions;

export default firmwareVersionSlice.reducer;
