import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  DeviceConfiguration,
  Variokey,
  VariokeyItem,
} from "../../../cobra-backend-client";
import { CustomSelect, CustomSelectOption } from "../../common/CustomSelect";

const UNKNOWN_VARIOKEY_NAME = "Unknown";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  variokeys: VariokeyItem[];
}

const VariokeyContainer = (props: Props) => {
  const [selectOptions, setSelectOptions] = useState<CustomSelectOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<VariokeyItem>();

  useEffect(() => {
    const variokeySelectOptions = props.variokeys.map((variokey) => {
      return {
        label: variokey.name,
        value: variokey.index,
      } as CustomSelectOption;
    });

    let variokey = props.variokeys.find(
      (variokey) => variokey.index === props.updatedDeviceConfiguration.varioKey
    );

    if (!variokey) {
      variokey = {
        name: UNKNOWN_VARIOKEY_NAME,
        index: props.updatedDeviceConfiguration.varioKey,
      } as VariokeyItem;

      const uknkownSelectOption = {
        label: variokey.name,
        value: variokey.index,
      } as CustomSelectOption;

      variokeySelectOptions.push(uknkownSelectOption);
    }

    setSelectOptions(variokeySelectOptions);
    setSelectedValue({ ...variokey });
  }, [props.variokeys, props.updatedDeviceConfiguration.varioKey]);

  const handleChange = (newValue: number) => {
    var variokey = props.variokeys.find(
      (variokey) => variokey.index === newValue
    );
    setSelectedValue(variokey);

    const updatedConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      varioKey: variokey?.index ?? 0,
    };

    props.setUpdatedDeiceConfiguration(updatedConfiguration);
  };

  const getSelectDisabledValues = () => {
    var disabledSelectVariokey = selectOptions.find(
      (v) => v.label === UNKNOWN_VARIOKEY_NAME
    )?.value;
    if (!disabledSelectVariokey) {
      return [];
    }
    return [disabledSelectVariokey];
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Variokey
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
        }}
      >
        <Grid
          item
          xs={4}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
            pt: 0,
          }}
        >
          <Typography>Company Variokey</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          sm={6}
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <CustomSelect
            selectedValue={selectedValue?.index?.toString() ?? ""}
            disabledValues={getSelectDisabledValues()}
            options={selectOptions}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { VariokeyContainer };
