import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { COBRA_API } from "../api/config";
import { DEVICE_STATUS_INDEX } from "../constants/common";
import {
  CONTROLLER_INSTALLATION_PAGE,
  DEVICE_STATUS_PAGE,
} from "../constants/pages";
import { RootState } from "../stores/store";

export const useDeviceMonitor = () => {
  const location = useLocation();
  const deviceId = useSelector((state: RootState) => state.device.deviceId);

  useEffect(() => {
    if (!deviceId || !location) return;
    if (
      location.pathname === DEVICE_STATUS_PAGE ||
      location.pathname === CONTROLLER_INSTALLATION_PAGE
    ) {
      if (
        location.state?.from?.pathname === DEVICE_STATUS_PAGE ||
        location.state?.from?.pathname === CONTROLLER_INSTALLATION_PAGE
      ) {
        return;
      }
      startMonitor(+deviceId);
    } else {
      if (
        location.state?.from?.pathname === DEVICE_STATUS_PAGE ||
        location.state?.from?.pathname === CONTROLLER_INSTALLATION_PAGE
      ) {
        stopMonitor(+deviceId);
      }
    }
  }, [deviceId, location]);

  const startMonitor = useCallback(
    async (deviceId: number | null) => {
      if (!deviceId) return;
      try {
        COBRA_API.DeviceMonitor.enableMonitoring(
          DEVICE_STATUS_INDEX.toString(),
          deviceId
        );
      } catch (error) {}
    },
    [deviceId]
  );

  const stopMonitor = useCallback(
    async (deviceId: number | null) => {
      if (!deviceId) return;
      try {
        COBRA_API.DeviceMonitor.disableMonitoring(
          DEVICE_STATUS_INDEX.toString(),
          deviceId
        );
      } catch (error) {}
    },
    [deviceId]
  );
};
