export const downloadFile = async (file: File, fileName: string) => {
  const fileArrayBuffer = await readFileContentAsBinary(file);

  const url = window.URL.createObjectURL(new Blob([fileArrayBuffer]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); // Set the desired filename

  link.click();

  window.URL.revokeObjectURL(url);
};

export const readFileContet = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target?.result as string;
      resolve(content);
    };
    reader.onerror = (event) => {
      reject(new Error("Error reading file."));
    };
    reader.readAsText(file);
  });
};


export const readFileContentAsBinary = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target?.result as ArrayBuffer;
      resolve(content);
    };
    reader.onerror = (event) => {
      reject(new Error("Error reading file."));
    };
    reader.readAsArrayBuffer(file);
  });
};
