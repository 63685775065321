import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import {
  PlatformSensorFootSwitchModeEnum,
  getPlatformSensorFootSwitchModeEnumValues,
  mapPlatformSensorFootSwitchModeValueToLabel,
} from "../../../models/PlatformSensorFootSwitchModeEnum";
import { CustomFab } from "../../common/CustomFab";
import { CustomImageRadio } from "../../common/CustomImageRadio";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  platformSensorActive: boolean | undefined;
}

const PlatformSensorFootSwitchMode = (props: Props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFootSwitchMode, setSelectedFootSwitchMode] =
    useState<PlatformSensorFootSwitchModeEnum>();

  useEffect(() => {
    if (props.updatedDeviceConfiguration.cableControl?.footSwitchMode != null) {
      setSelectedFootSwitchMode(
        props.updatedDeviceConfiguration.cableControl?.footSwitchMode
      );
    }
  }, [props.updatedDeviceConfiguration.cableControl?.footSwitchMode]);

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const footSwitchMode = +value as PlatformSensorFootSwitchModeEnum;
    setSelectedFootSwitchMode(footSwitchMode);

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      cableControl: {
        ...props.updatedDeviceConfiguration.cableControl,
        footSwitchMode: footSwitchMode,
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Platform foot switch mode </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Typography sx={{ pr: 1 }}>
            {mapPlatformSensorFootSwitchModeValueToLabel(
              selectedFootSwitchMode
            )}
          </Typography>
          <Box>
            <CustomFab
              icon={<EditIcon />}
              onClick={() => setOpenDialog(true)}
              disabled={!props.platformSensorActive}
            />
          </Box>
        </Box>
      </Grid>

      <Dialog fullScreen open={openDialog} onClose={() => setOpenDialog(false)}>
        <Box sx={{ m: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Foot switch mode</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RadioGroup
              value={selectedFootSwitchMode}
              onChange={handleRadioChange}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  mb: 3,
                }}
              >
                {getPlatformSensorFootSwitchModeEnumValues().map((v) => {
                  return (
                    <Fragment key={`psfs_${v}`}>
                      <CustomImageRadio
                        value={v}
                        checked={+v === selectedFootSwitchMode}
                        imageName={`footswitch${v}.svg`}
                        label={mapPlatformSensorFootSwitchModeValueToLabel(+v)}
                      />
                    </Fragment>
                  );
                })}
              </Box>
            </RadioGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                md: "center",
              },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mb: 3,
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Assembly Instructions:
              </Typography>
              <Typography>IN1: U-Up </Typography>
              <Typography>IN2: D-Down </Typography>
              <Typography>Series: C-Common </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button variant="contained" onClick={() => setOpenDialog(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export { PlatformSensorFootSwitchMode };
