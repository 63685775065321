import { Chip, Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import { ReactComponent as TwoHIcon } from "../../../assets/Zeichnung_2H.svg";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { CableControlModeEnum } from "../../../models/CableControlModeEnum";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const TwoHEnabled = (props: Props) => {
  const [cableControlEnable2H, setCableControlEnable2H] = useState(
    props.updatedDeviceConfiguration.cableControl?.cableControlEn2H
  );

  useEffect(() => {
    setCableControlEnable2H(
      props.updatedDeviceConfiguration.cableControl?.cableControlEn2H
    );
  }, [props.updatedDeviceConfiguration.cableControl?.cableControlEn2H]);

  useEffect(() => {
    if (
      props.updatedDeviceConfiguration?.cableControl?.cableControlMode ===
      CableControlModeEnum.MODE_2
    ) {
      updateConfiguration(false);
    }
  }, [props.updatedDeviceConfiguration?.cableControl?.cableControlMode]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setCableControlEnable2H(checked);
    updateConfiguration(checked);
  };

  const updateConfiguration = (cableControlEn2H: boolean) => {
    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      cableControl: {
        ...props.updatedDeviceConfiguration.cableControl,
        cableControlEn2H: cableControlEn2H,
      },
    };
    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>
          {/* <TwoHIcon
            style={{ width: "24px", height: "24px", marginRight: "10px" }}
          /> */}
          IN 7, 2H
        </Typography>{" "}
        <Chip
          sx={{ ml: 1 }}
          variant="outlined"
          label={cableControlEnable2H ? "on" : "off"}
          color={cableControlEnable2H ? "success" : "error"}
          size="small"
        />
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={cableControlEnable2H}
          onChange={onChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
    </>
  );
};

export { TwoHEnabled };
