import { createTheme } from "@mui/material";

export const customizedTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#401613",
      dark: "#dc2c23",
      light: "#b34c46",
    },
    secondary: {
      main: "#167679",
      light: "#2c8689",
      dark: "#006669",
    },
    error: {
      main: "#c00029",
      light: "#f61646",
      dark: "#900c29",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
  },
});

export const drawerWidth = 250;
