import { Alert, AlertColor, AlertTitle, Stack } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  alertSeverity: string;
  alertText: string;
  autoHideDurationMs: number;
  onAlertClose: () => void;
  alertAdditionalInfo?: string;
}

const CustomAlert = (props: Props) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);

    const timeout = setTimeout(() => {
      setVisible(false);
      props.onAlertClose();
    }, props.autoHideDurationMs);

    return () => clearTimeout(timeout);
  }, [props.autoHideDurationMs, props.onAlertClose]);
  return (
    <>
      {visible && (
        <Stack sx={{ width: "100%", my: 2 }} spacing={2}>
          <Alert
            severity={props.alertSeverity as AlertColor}
            onClose={() => setVisible(false)}
          >
            <AlertTitle>{props.alertText}</AlertTitle>
            {props.alertAdditionalInfo}
          </Alert>
        </Stack>
      )}
    </>
  );
};

export { CustomAlert };
