import { Box, Chip, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  platformAngle: number;
  plSensor: boolean;
}

const PlatformAngle = (props: Props) => {
  const [platformAngleDecimalValue, setPlatformAngleDecimalValue] =
    useState("");
  const [platformAngleTextMessage, setPlatformAngleTextMessage] =
    useState("");

  useEffect(() => {
    const angleValue =
      props.platformAngle != 0 ? props.platformAngle / 100 : 0;

    if (angleValue >= 80 && angleValue < 128) {
      setPlatformAngleTextMessage("close");
    } else if (angleValue >= 10 && angleValue < 80) {
      setPlatformAngleTextMessage("open");
    } else if (angleValue >= -10 && angleValue < 10) {
      setPlatformAngleTextMessage("horizontal working position");
    } else if (angleValue >= -90 && angleValue < -10) {
      setPlatformAngleTextMessage("under working position");
    }
    setPlatformAngleDecimalValue(angleValue.toFixed(1) + "°");
  }, [props.platformAngle]);

  return (
    <>
      {props.plSensor && (
        <>
          <Grid
            item
            xs={4}
            sm={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <Typography>Platform angle</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sm={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            {platformAngleDecimalValue != "" && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Chip
                    label={platformAngleTextMessage}
                    variant="outlined"
                    sx={{ mr: 1 }}
                  />
                  <Typography>{platformAngleDecimalValue} </Typography>
                </Box>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export { PlatformAngle };
