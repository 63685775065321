import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export interface CustomSelectOption {
  label: string;
  value: number;
}

interface Props {
  label?: string;
  selectedValue: string;
  onChange: (newValue: number) => void;
  options: CustomSelectOption[];
  disabledValues?: number[];
}

const CustomSelect = (props: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const newValue = +event.target.value;
    props.onChange(newValue);
  };

  return (
    <Box sx={{ minWidth: 175, flex: 1, flexFlow: 1 }}>
      <FormControl fullWidth size="small">
        <InputLabel>{props.label ?? ""}</InputLabel>
        <Select value={props.selectedValue} onChange={handleChange}>
          {props.options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={props.disabledValues?.some((v) => v === option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export { CustomSelect };
