import { Grid } from "@mui/material";
import { useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { AngleCalibration } from "./AngleCalibration";
import { DeviceRestart } from "./DeviceRestart";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";

interface Props {
  deviceConfiguration: DeviceConfiguration;
}

const ControllerInstallationContainer = (props: Props) => {
  const deviceId = useSelector((state: RootState) => state.device.deviceId);

  const [updatedDeviceConfiguration, setUpdatedDeiceConfiguration] =
    useState<DeviceConfiguration>(props.deviceConfiguration);
  const [showAngleCalibrationProgress, setShowAngleCalibrationProgress] =
    useState(false);

  return (
    <>
      {deviceId != null && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AngleCalibration
              deviceId={+deviceId}
              updatedDeviceConfiguration={updatedDeviceConfiguration}
              setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
              showAngleCalibrationProgress={showAngleCalibrationProgress}
              setShowAngleCalibrationProgress={setShowAngleCalibrationProgress}
            />
            <Grid item xs={12}>
              <DeviceRestart
                deviceId={+deviceId}
                showAngleCalibrationProgress={showAngleCalibrationProgress}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { ControllerInstallationContainer };
