import { Link, useLocation } from "react-router-dom";
import { CustomDrawerMenuItem } from "../../routes/CustomDrawerMenuItem";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

interface InternalListItemButtonProps {
    item: CustomDrawerMenuItem;
  }
  
  export const InternalListItemButton = (props: InternalListItemButtonProps) => {
    const location = useLocation();
  
    return (
      <ListItemButton
        component={Link}
        to={props.item.link}
        state={{ from: location }}
        selected={props.item.link === location.pathname}
      >
        <ListItemIcon>{props.item.icon}</ListItemIcon>
        <ListItemText primary={props.item.text} />
      </ListItemButton>
    );
  };
  
  interface ExternalListItemButtonProps {
    item: CustomDrawerMenuItem;
  }
  
  export const ExternalListItemButton = (props: ExternalListItemButtonProps) => {
    return (
      <ListItemButton
        component="a"
        onClick={() => window.open(props.item.link, "_blank")}
      >
        <ListItemIcon>{props.item.icon}</ListItemIcon>
        <ListItemText primary={props.item.text} />
      </ListItemButton>
    );
  };