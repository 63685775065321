import { Button, ButtonProps, Tooltip } from "@mui/material";

interface Props extends ButtonProps {
  tooltipText?: string;
  buttonText?: string;
}

const ButtonWithTooltip = (props: Props) => {
  const { tooltipText, buttonText, ...rest } = props;
  return (
    <Tooltip title={tooltipText} placement="bottom">
      <Button component="span" {...rest}>
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export { ButtonWithTooltip };
