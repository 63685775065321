import { IconButton, IconButtonProps, Tooltip } from "@mui/material";

interface Props extends IconButtonProps {
  tooltipText?: string;
  icon: JSX.Element;
}

const IconButtonWithTooltip = (props: Props) => {
  const { tooltipText, icon, ...rest } = props;
  return (
    <Tooltip title={tooltipText} placement="bottom">
      <IconButton color="primary" {...rest}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export { IconButtonWithTooltip };
