import { ThemeProvider } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StompSessionProvider } from "react-stomp-hooks";
import { setAuthorizationHeader } from "../api/config";
import { ErrorBoundary } from "../components/common/ErrorBoundary";
import { STORAGE_JWT_KEY } from "../constants/browserStorage";
import { AppRoutes } from "../routes/AppRoutes";
import { setIsStompConnected } from "../stores/slices/stompSlice";
import { setUserToken } from "../stores/slices/userSlice";
import { customizedTheme } from "../theme/theme";
import { readFromStorage, saveToStorage } from "../utils/browserStorage";
import { isJwtTokenExpired } from "../utils/jwt";
import { StylesProvider } from "./StylesProvider";

const WEB_SOCKET_URL = `/ws`;

const MainProvider = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = readFromStorage(STORAGE_JWT_KEY);
    setAuthorizationHeader(token);
    if (token) {
      const isTokenExpired = isJwtTokenExpired(token);
      if (isTokenExpired) {
        saveToStorage(localStorage, STORAGE_JWT_KEY, null);
        saveToStorage(sessionStorage, STORAGE_JWT_KEY, null);
      } else {
        dispatch(setUserToken(token));
      }
    }
  }, []);

  return (
    <ErrorBoundary>
      <StompSessionProvider
        url={WEB_SOCKET_URL}
        onWebSocketClose={() => {}}
        onConnect={() => {
          dispatch(setIsStompConnected(true));
        }}
        onDisconnect={() => {
          dispatch(setIsStompConnected(true));
        }}
      >
        <ThemeProvider theme={customizedTheme}>
          <StylesProvider>
            <AppRoutes />
          </StylesProvider>
        </ThemeProvider>
      </StompSessionProvider>
    </ErrorBoundary>
  );
};

export { MainProvider };
