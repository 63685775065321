import { Box, CssBaseline } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

const StylesProvider = (props: Props) => {
  return (
    <>
      <CssBaseline />
      <Box sx={{ m: 2 }}>{props.children}</Box>
    </>
  );
};

export { StylesProvider };
