import { Grid, Switch, Typography } from "@mui/material";
import {
  DeviceConfiguration,
  OutputConfig,
} from "../../../cobra-backend-client";
import { renderOrderByIndexSvgButtonsComponent } from "../../../utils/renderOrderByIndexSvgButtonsComponent";
import { Fragment } from "react";

const CABLE_CONTROL_SVG_INDEXES = [0, 1, 2, 3];

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}

const OutCableControl = (props: Props) => {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    buttonIndex: number
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    switch (buttonIndex) {
      case 0:
        newOutputConfigTable[props.outAllocationIndex].cableControlClose =
          checked;
        break;
      case 1:
        newOutputConfigTable[props.outAllocationIndex].cableControlUp = checked;
        break;
      case 2:
        newOutputConfigTable[props.outAllocationIndex].cableControlOpen =
          checked;
        break;
      case 3:
        newOutputConfigTable[props.outAllocationIndex].cableControlDown =
          checked;
        break;
    }


    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  const getCableControlValueByIndex = (index: number) => {
    switch (index) {
      case 0:
        return props.updatedDeviceConfiguration.out?.outputConfigTable?.[
          props.outAllocationIndex
        ].cableControlClose;
      case 1:
        return props.updatedDeviceConfiguration.out?.outputConfigTable?.[
          props.outAllocationIndex
        ].cableControlUp;
      case 2:
        return props.updatedDeviceConfiguration.out?.outputConfigTable?.[
          props.outAllocationIndex
        ].cableControlOpen;
      case 3:
        return props.updatedDeviceConfiguration.out?.outputConfigTable?.[
          props.outAllocationIndex
        ].cableControlDown;
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography variant="h6">Cable Control</Typography>
      </Grid>

      {CABLE_CONTROL_SVG_INDEXES.map((index) => (
        <Fragment key={`CABLE_CONTROL_SVG_INDEX_${index}`}>
          <Grid
            item
            xs={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            {renderOrderByIndexSvgButtonsComponent(index)}
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Switch
              checked={getCableControlValueByIndex(index)}
              onChange={(e, checked) => onChange(e, checked, index)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Fragment>
      ))}
    </>
  );
};

export { OutCableControl };
