import { Fab, FabProps } from "@mui/material";
import { ReactElement, ReactNode, cloneElement } from "react";

interface Props extends FabProps {
  icon: ReactNode;
  onClick: () => void;
}

const CustomFab = (props: Props) => {
  const { icon, onClick, ...rest } = props;
  return (
    <>
      <Fab
        {...rest}
        color="secondary"
        aria-label="edit"
        style={{
          width: "30px",
          height: "30px",
        }}
        onClick={props.onClick}
      >
        {cloneElement(props.icon as ReactElement, {
          style: {
            width: "18px",
            height: "18x",
          },
        })}
      </Fab>
    </>
  );
};

export { CustomFab };
