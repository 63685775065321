export enum CableControlModeEnum {
  MODE_OFF = 0,
  MODE_1 = 1,
  MODE_2 = 2,
  MODE_3 = 3,
  MODE_PLATFORM_SENSOR = 20
}

const cableControlModeLabels = {
  [CableControlModeEnum.MODE_OFF]: "Mode off",
  [CableControlModeEnum.MODE_1]: "Mode 1",
  [CableControlModeEnum.MODE_2]: "Mode 2",
  [CableControlModeEnum.MODE_3]: "Mode 3",
  [CableControlModeEnum.MODE_PLATFORM_SENSOR]: "Mode Platform sensor",

};

export const mapCableControlModeValueToLabel = (
  cableControlMode: CableControlModeEnum | undefined
): string => {
  if (cableControlMode == null) {
    return "Unknown";
  }

  return cableControlModeLabels[cableControlMode] || "Unknown";
};

export const getCableControlModeEnumValues = () => {
  return Object.keys(CableControlModeEnum).filter((item) => {
    return !isNaN(Number(item));
  });
};
