import { Chip, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  RssiZoneEnum,
  RssiZoneMessages,
} from "../../../models/dto/ws/monitoring/RssiZoneEnum";

interface Props {
  rssiZone: RssiZoneEnum;
}

const HandheldZone = (props: Props) => {
  const [chipBackroundColor, setChipBackroundColor] = useState("");

  useEffect(() => {
    if (props.rssiZone === RssiZoneEnum.WORK_ZONE) {
      setChipBackroundColor("blue");
    } else if (props.rssiZone === RssiZoneEnum.SIDE_ZONE) {
      setChipBackroundColor("green");
    } else if (props.rssiZone === RssiZoneEnum.BACK_ZONE) {
      setChipBackroundColor("yellow");
    } else {
      setChipBackroundColor("");
    }
  }, [props.rssiZone]);

  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Handheld zone </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Chip
          label={RssiZoneMessages[props.rssiZone] ?? "Unknown"}
          variant="outlined"
          sx={{ borderColor: chipBackroundColor, color: chipBackroundColor }}
        />
      </Grid>
    </>
  );
};

export { HandheldZone };
