import { COBRA_API } from "./config";

export const generateJwtToken = (username: string, password: string) => {
  return COBRA_API.JwtToken.generateJwtToken({
    headers: {
      Authorization: `Basic ${encodeUsernamePassword(username, password)}`,
      // Prevent basic authentication popup in case of wrong username or password.
      "X-Requested-With": "XMLHttpRequest",
    },
  });
};

const encodeUsernamePassword = (username: string, password: string) => {
  const credentials = username + ":" + password;
  return window.btoa(credentials);
};
