import CloseIcon from "@mui/icons-material/Close";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  Stack,
} from "@mui/material";
import { IMessage } from "@stomp/stompjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  TOPIC_FIRMWARE_UPDATE_COMPLETED,
  TOPIC_FIRMWARE_UPDATE_PACKET_STATUS,
  TOPIC_FIRMWARE_UPDATE_STATUS,
} from "../../../constants/topics";
import { useCustomSubscriptionManager } from "../../../hooks/useCustomSubscriptionManager";
import { FirmwareMicroControllerUpdateStatus } from "../../../models/FirmwareMicroControllerUpdateStatus";
import { FirmwareUpdateCompleteDto } from "../../../models/dto/ws/firmware/FirmwareUpdateCompleteDto";
import {
  FirmwareUpdateMessageType,
  getFirmwareUpdateMessageTypeValueByKey,
} from "../../../models/dto/ws/firmware/FirmwareUpdateMessageType";
import { FirmwareUpdatePacketStatusDto } from "../../../models/dto/ws/firmware/FirmwareUpdatePacketStatusDto";
import {
  FirmwareUpdateState,
  getFirmwareUpdateStatesEnumValueByKey,
} from "../../../models/dto/ws/firmware/FirmwareUpdateState";
import { FirmwareUpdateStatusDto } from "../../../models/dto/ws/firmware/FirmwareUpdateStatusDto";
import { RootState } from "../../../stores/store";
import { readFileContentAsBinary } from "../../../utils/file";
import { ButtonWithTooltip } from "../../common/ButtonWithTooltip";
import { CustomProgressBar } from "../../common/CustomProgressBar";
import { FirmwareUpdateSkipErrorsSwitch } from "./FirmwareUpdateSkipErrorsSwitch";
import { COBRA_API } from "../../../api/config";

// These states must be in the parent container, because in this component the states are reset after microcontrolelr update.
// They are used for storing information that can be displayed after the all updates are finished
interface Props {
  selectedFile: File | undefined;
  setFirmwareError: React.Dispatch<React.SetStateAction<string>>;
  setIsNewFirmwareInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  isConfigurationUpdatedSuccessfully: boolean;
  setIsConfigurationUpdatedSuccessfully: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setFirmwareUpdateInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  firmwareUpdateInProgress: boolean;
  skipConfigurationErrorsSwitchChecked: boolean;
  setSkipConfigurationErrorsSwitchChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const FirmwareUpdate = (props: Props) => {
  const deviceId = useSelector((state: RootState) => state.device.deviceId);
  // Schmidiger users can make update with configuration erros
  const isSchmidigerUser = useSelector(
    (state: RootState) => state.user.isSchmidigerUser
  );

  const { subscribeToTopic, unsubscribeFromTopic } =
    useCustomSubscriptionManager();

  // Fimrware update
  const [firmwareUpdateStart, setFirmwareUpdateStart] = useState(false);
  const [firmwareTransferPacketProgress, setFirmwareTransferPacketProgress] =
    useState(-1);
  const [firmwareTransferPacketdMsg, setFirmwareTransferPacketdMsg] =
    useState("");
  const [firmwareInstallMessages, setFirmwareInstallMessages] = useState<
    string[]
  >([]);
  const [firmwareInstallProgress, setFirmwareInstallProgress] = useState(-1);
  const [deviceMicroControllerMsg, setDeviceMicroControllerMsg] = useState("");
  // Fimrware update completion log
  const [
    firmwareMicroControllerUpdateStatus,
    setFirmwareMicroControllerUpdateStatus,
  ] = useState<FirmwareMicroControllerUpdateStatus[]>([]);
  const [completeFirmwareUpdateAlertOpen, setCompleteFirmwareUpdateAlertOpen] =
    useState(false);

  const handleIsCloudUpload = async (isCloudUpload: boolean) => {
    if (!deviceId) return;
    if (!isCloudUpload && !props.selectedFile) return;

    props.setIsConfigurationUpdatedSuccessfully(true);
    try {
      subscribeForFirmwareUpdatesMessages();
      const skipConfigurationErrors =
        props.skipConfigurationErrorsSwitchChecked;

      const response = await COBRA_API.FirmwareUpdate.updateFirmware(
        +deviceId,
        skipConfigurationErrors,
        props.selectedFile
      );
      props.setFirmwareUpdateInProgress(true);
    } catch (error: any) {}
  };

  const subscribeForFirmwareUpdatesMessages = () => {
    subscribeToFirmwareUpdateStatus();
    subscribeToFirmwareUpdatePacketStatus();
    setFirmwareUpdateStart(true);
    subscribeToFirmwareUpdateCompleteStatus();
  };

  const subscribeToFirmwareUpdatePacketStatus = () => {
    subscribeToTopic(
      TOPIC_FIRMWARE_UPDATE_PACKET_STATUS,
      onFirmwarePacketUpdatePacketMessage
    );
  };

  const subscribeToFirmwareUpdateStatus = () => {
    subscribeToTopic(
      TOPIC_FIRMWARE_UPDATE_STATUS,
      onFirmwareUpdateStatusMessage
    );
  };

  const subscribeToFirmwareUpdateCompleteStatus = () => {
    subscribeToTopic(
      TOPIC_FIRMWARE_UPDATE_COMPLETED,
      onFirmwareUpdateCompletedMessage
    );
  };

  const onFirmwareUpdateStatusMessage = (message: IMessage) => {
    const messageBody = JSON.parse(message.body) as FirmwareUpdateStatusDto;
    if (messageBody.deviceId.toString() != deviceId) return;

    const messageTypeEnum = getFirmwareUpdateMessageTypeValueByKey(
      messageBody.messageType
    );

    switch (messageTypeEnum) {
      case FirmwareUpdateMessageType.STATE_CHANGE: {
        const additionalMessageEnum = getFirmwareUpdateStatesEnumValueByKey(
          messageBody.state
        );
        setFirmwareInstallMessages((prevArr) => {
          if (!additionalMessageEnum) {
            return [...prevArr];
          }
          const msg = additionalMessageEnum.toString();

          const uniqueSet = new Set(prevArr);
          if (!uniqueSet.has(msg)) {
            uniqueSet.add(msg);
          }
          const uniqueArray = Array.from(uniqueSet);

          return uniqueArray;
        });
        if (
          additionalMessageEnum === FirmwareUpdateState.NEW_FIRMWARE_INSTALLED
        ) {
          const microController = messageBody.controller;
          onFirmwareInstalled(
            true,
            microController,
            `${additionalMessageEnum} for microcontroller ${microController}`
          );
        }
        if (
          additionalMessageEnum ===
          FirmwareUpdateState.NEW_FIRMWARE_CAN_NOT_BE_INSTALLED
        ) {
          const microController = messageBody.controller;
          onFirmwareInstalled(
            false,
            microController,
            `${additionalMessageEnum} for microcontroller ${microController}`
          );
        }
        if (
          additionalMessageEnum ===
          FirmwareUpdateState.CUSTOM_ERROR_FIRMWARE_PARSE
        ) {
          const microController = messageBody.controller;
          onFirmwareInstalled(
            false,
            microController,
            `${additionalMessageEnum} for microcontroller ${microController}`
          );
        }
        if (
          additionalMessageEnum ===
            FirmwareUpdateState.CUSTOM_ERROR_DEVICE_CONFIGURATION_EXPORT ||
          additionalMessageEnum ===
            FirmwareUpdateState.CUSTOM_ERROR_DEVICE_TRANSMITTER_MEMORY_CONFIGURATION_EXPORT ||
          additionalMessageEnum ===
            FirmwareUpdateState.CUSTOM_ERROR_DEVICE_CONFIGURATION_DEVICE_VERSION_INCOMPATIBLE
        ) {
          props.setIsConfigurationUpdatedSuccessfully(false);

          if (isSchmidigerUser && props.skipConfigurationErrorsSwitchChecked) {
            props.setFirmwareUpdateInProgress(true);
          } else {
            props.setFirmwareUpdateInProgress(false);
          }

          onFirmwareInstalled(false, "", additionalMessageEnum);
        }
        if (
          additionalMessageEnum ===
            FirmwareUpdateState.CUSTOM_ERROR_DEVICE_CONFIGURATION_IMPORT ||
          additionalMessageEnum ===
            FirmwareUpdateState.CUSTOM_ERROR_DEVICE_TRANSMITTER_MEMORY_CONFIGURATION_IMPORT
        ) {
          props.setIsConfigurationUpdatedSuccessfully(false);

          if (isSchmidigerUser && props.skipConfigurationErrorsSwitchChecked) {
            props.setFirmwareUpdateInProgress(true);
          } else {
            props.setFirmwareUpdateInProgress(false);
          }

          onFirmwareInstalled(
            false,
            "",
            `${additionalMessageEnum} ${
              messageBody.deviceConfigurationUpdateResponse ?? ""
            } `
          );
        }
        break;
      }
      case FirmwareUpdateMessageType.PROGRESS: {
        setFirmwareInstallProgress(messageBody.progress);
        break;
      }
      default: {
        const microController = messageBody.controller;
        const firmwareError =
          getFirmwareUpdateMessageTypeValueByKey(
            messageTypeEnum as FirmwareUpdateMessageType
          ) ?? FirmwareUpdateMessageType.ERROR_RESEND_ERR;
        props.setFirmwareError(firmwareError);
        onFirmwareInstalled(
          false,
          microController,
          `${firmwareError} for microcontroller ${messageBody.controller}`
        );
      }
    }
  };

  const onFirmwarePacketUpdatePacketMessage = (message: IMessage) => {
    const messageBody = JSON.parse(
      message.body
    ) as FirmwareUpdatePacketStatusDto;
    if (messageBody.deviceId.toString() != deviceId) return;

    if (messageBody.msgNr === 1) {
      setFirmwareUpdateStart(true);
      setDeviceMicroControllerMsg(
        `Fimware update for ${messageBody.controller} started`
      );
      setFirmwareTransferPacketProgress(0);
      setFirmwareTransferPacketdMsg(`Started downloading a new firmware.`);
    }

    const progressInPercentage = (
      (messageBody.msgNr / messageBody.totalMsgCount) *
      100
    ).toFixed(0);
    setFirmwareTransferPacketProgress(+progressInPercentage);

    if (messageBody.msgNr === messageBody.totalMsgCount) {
      setFirmwareTransferPacketdMsg(
        `A new firmware was downloaded successfully. Updating...`
      );
      setFirmwareTransferPacketProgress(-1);
    }
  };

  const onFirmwareInstalled = (
    success: boolean,
    microController: string,
    alertMsg?: string
  ) => {
    props.setIsNewFirmwareInstalled(success);
    props.setAlertMessage(alertMsg ?? "");
    setDeviceMicroControllerMsg("");
    setFirmwareTransferPacketProgress(-1);
    setFirmwareInstallProgress(-1);
    setFirmwareUpdateStart(false);
    setFirmwareTransferPacketdMsg("");
    setFirmwareInstallMessages([]);
    props.setShowAlert(true);
    // This is used when the configuration can not be imported to the MC, but this happens after firmware update was successful
    if (microController.trim() != "") {
      setFirmwareMicroControllerUpdateStatus((prevArr) => {
        const microControllerObj = {
          microController,
          success,
        };
        const uniqueArray = [...prevArr];
        const existingIndex = uniqueArray.findIndex(
          (obj) => obj.microController === microControllerObj.microController
        );

        if (existingIndex !== -1) {
          uniqueArray[existingIndex] = microControllerObj;
        } else {
          uniqueArray.push(microControllerObj);
        }
        return [...uniqueArray];
      });
    }
  };

  const onFirmwareUpdateCompletedMessage = (message: IMessage) => {
    const messageBody = JSON.parse(message.body) as FirmwareUpdateCompleteDto;

    if (messageBody.deviceId != deviceId) return;
    if (messageBody.updateCompleted) {
      setCompleteFirmwareUpdateAlertOpen(true);
    }
  };

  const resetFirmwareCompleteUpdate = () => {
    unsubscribeFromTopic(TOPIC_FIRMWARE_UPDATE_PACKET_STATUS);
    unsubscribeFromTopic(TOPIC_FIRMWARE_UPDATE_STATUS);
    unsubscribeFromTopic(TOPIC_FIRMWARE_UPDATE_COMPLETED);

    setFirmwareMicroControllerUpdateStatus([]);
    props.setIsConfigurationUpdatedSuccessfully(true);
    setCompleteFirmwareUpdateAlertOpen(false);
    props.setFirmwareUpdateInProgress(false);
  };

  return (
    <>
      {isSchmidigerUser && (
        <FirmwareUpdateSkipErrorsSwitch
          switchChecked={props.skipConfigurationErrorsSwitchChecked}
          setSwitchChecked={props.setSkipConfigurationErrorsSwitchChecked}
          disabled={props.firmwareUpdateInProgress}
        />
      )}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <ButtonWithTooltip
          buttonText="Update with cloud"
          tooltipText="Update device firmware"
          variant="contained"
          onClick={() => handleIsCloudUpload(true)}
          startIcon={<CloudQueueIcon />}
          disabled={props.firmwareUpdateInProgress}
        />

        <ButtonWithTooltip
          buttonText="Update with file"
          tooltipText="Update device firmware"
          variant="contained"
          onClick={() => handleIsCloudUpload(false)}
          startIcon={<InsertDriveFileIcon />}
          disabled={props.firmwareUpdateInProgress}
        />
      </Stack>

      {isSchmidigerUser && props.skipConfigurationErrorsSwitchChecked && (
        <Alert severity="info" sx={{ my: 1 }}>
          Development mode ON: Erorrs by configuration won't interrupt the
          update
        </Alert>
      )}

      {firmwareUpdateStart && (
        <Box>
          <LinearProgress color="inherit" sx={{ maxWidth: 300, my: 2 }} />
        </Box>
      )}

      {deviceMicroControllerMsg.trim() !== "" && (
        <Box sx={{ my: 1 }}>{deviceMicroControllerMsg}</Box>
      )}

      {firmwareTransferPacketProgress >= 0 && (
        <Box sx={{ my: 1 }}>
          <Box sx={{ my: 1 }}>{firmwareTransferPacketdMsg}</Box>
          <CustomProgressBar value={firmwareTransferPacketProgress} />
        </Box>
      )}

      {firmwareInstallMessages.map((msg, index) => (
        <Box sx={{ my: 2 }} key={msg + new Date().getMilliseconds + index}>
          {msg}
        </Box>
      ))}

      {firmwareInstallProgress >= 0 && (
        <Box sx={{ my: 1 }}>
          <Box sx={{ my: 1 }}>Installing firmware</Box>
          <CustomProgressBar value={firmwareInstallProgress} />
        </Box>
      )}

      <Collapse in={completeFirmwareUpdateAlertOpen}>
        <Alert
          variant="outlined"
          severity={
            firmwareMicroControllerUpdateStatus.every((c) => c.success) &&
            props.isConfigurationUpdatedSuccessfully
              ? "success"
              : "error"
          }
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={resetFirmwareCompleteUpdate}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ my: 2 }}
        >
          <AlertTitle sx={{ fontWeight: 600 }}>
            {firmwareMicroControllerUpdateStatus.every((c) => c.success) &&
            props.isConfigurationUpdatedSuccessfully
              ? "Firmware update was successful"
              : "Firmware update was not successful"}
          </AlertTitle>
          {firmwareMicroControllerUpdateStatus.map((controller) => (
            <Box key={controller.microController}>{`${
              controller.microController
            } update was ${
              controller.success ? "successful" : "not successful"
            } `}</Box>
          ))}
          Device Configuration was
          {props.isConfigurationUpdatedSuccessfully
            ? " updated successful"
            : " not updated successful"}
        </Alert>
      </Collapse>
    </>
  );
};

export { FirmwareUpdate };
