import { Box, FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";

interface Props {
  switchChecked: boolean;
  setSwitchChecked: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

const FirmwareUpdateSkipErrorsSwitch = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSwitchChecked(event.target.checked);
  };

  return (
    <Box>
      <FormControlLabel
        sx={{ m: 0 }}
        control={
          <Switch
            size="medium"
            checked={props.switchChecked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
            disabled={props.disabled}
          />
        }
        label="Update with ignored configuration errors"
        labelPlacement="start"
      />
    </Box>
  );
};

export { FirmwareUpdateSkipErrorsSwitch };
