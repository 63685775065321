import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { OutAngleSectors } from "./OutAngleSectors";
import { OutAutoTilt } from "./OutAutoTilt";
import { OutCableControl } from "./OutCableControl";
import { OutLockAndSaveZone } from "./OutLockAndSaveZone";
import { OutLogicOperation } from "./OutLogicOperation";
import { OutRadioControl } from "./OutRadioControl";
import { OutVred } from "./OutVred";
import { customizedTheme } from "../../../theme/theme";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  openDialog: boolean;
  onDialogClose: () => void;
  dialogTitle: string;
  outAllocationIndex: number;
}

const OutAllocationDialog = (props: Props) => {
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );

  return (
    <>
      <Dialog
        fullScreen
        open={props.openDialog}
        onClose={props.onDialogClose}
        keepMounted
        scroll="paper"
      >
        <Container
          maxWidth={isScreenSizeGreaterThanSm ? "sm" : false}
          disableGutters
        >
          <Box sx={{ m: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">{props.dialogTitle}</Typography>
            </Box>
            <Grid container spacing={1} mb={2}>
              <OutLogicOperation
                updatedDeviceConfiguration={props.updatedDeviceConfiguration}
                setUpdatedDeiceConfiguration={
                  props.setUpdatedDeiceConfiguration
                }
                outAllocationIndex={props.outAllocationIndex}
              />
              <OutRadioControl
                updatedDeviceConfiguration={props.updatedDeviceConfiguration}
                setUpdatedDeiceConfiguration={
                  props.setUpdatedDeiceConfiguration
                }
                outAllocationIndex={props.outAllocationIndex}
              />
              <OutCableControl
                updatedDeviceConfiguration={props.updatedDeviceConfiguration}
                setUpdatedDeiceConfiguration={
                  props.setUpdatedDeiceConfiguration
                }
                outAllocationIndex={props.outAllocationIndex}
              />
              <OutAngleSectors
                updatedDeviceConfiguration={props.updatedDeviceConfiguration}
                setUpdatedDeiceConfiguration={
                  props.setUpdatedDeiceConfiguration
                }
                outAllocationIndex={props.outAllocationIndex}
              />
              <OutVred
                updatedDeviceConfiguration={props.updatedDeviceConfiguration}
                setUpdatedDeiceConfiguration={
                  props.setUpdatedDeiceConfiguration
                }
                outAllocationIndex={props.outAllocationIndex}
              />
              <OutLockAndSaveZone
                updatedDeviceConfiguration={props.updatedDeviceConfiguration}
                setUpdatedDeiceConfiguration={
                  props.setUpdatedDeiceConfiguration
                }
                outAllocationIndex={props.outAllocationIndex}
              />
              <OutAutoTilt
                updatedDeviceConfiguration={props.updatedDeviceConfiguration}
                setUpdatedDeiceConfiguration={
                  props.setUpdatedDeiceConfiguration
                }
                outAllocationIndex={props.outAllocationIndex}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button variant="contained" onClick={props.onDialogClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Container>
      </Dialog>
    </>
  );
};

export { OutAllocationDialog };
