import { Grid } from "@mui/material";
import { IMessage } from "@stomp/stompjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Device } from "../cobra-backend-client";
import { FirmwareUpdateContainer } from "../components/device/firmware/FirmwareUpdateContainer";
import { DeviceFirmwareVersionComponent } from "../components/device/version/DeviceFirmwareVersionComponent";
import { TOPIC_ONLINE_DEVICES } from "../constants/topics";
import { useCustomSubscriptionManager } from "../hooks/useCustomSubscriptionManager";
import { setDeviceVersion } from "../stores/slices/deviceSlice";
import { RootState } from "../stores/store";
import { retrieveFirmwareVersionFromServer } from "../utils/retrieveFirmwareVersion";

const FirmwareUpdatePage = () => {
  const { subscribeToTopic } = useCustomSubscriptionManager();
  const deviceId = useSelector((state: RootState) => state.device.deviceId);
  const dispatch = useDispatch();

  useEffect(() => {
    subscribeToOnlineDevices();
    retrieveFirmwareVersionFromServer(dispatch);
  }, []);

  const subscribeToOnlineDevices = () => {
    subscribeToTopic(TOPIC_ONLINE_DEVICES, onOnlineDeviceMessage);
  };

  const onOnlineDeviceMessage = (message: IMessage) => {
    const messageBody = JSON.parse(message.body) as Device;
    const deviceVersion = messageBody.version;
    if (messageBody.deviceId === deviceId) {
      dispatch(setDeviceVersion(deviceVersion));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DeviceFirmwareVersionComponent />
      </Grid>
      <Grid item md={12}>
        <FirmwareUpdateContainer />
      </Grid>
    </Grid>
  );
};

export { FirmwareUpdatePage };
