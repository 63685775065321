import { useSelector } from "react-redux";
import { CustomDrawerMenuItem } from "../../routes/CustomDrawerMenuItem";
import { RootState } from "../../stores/store";
import {
  ExternalListItemButton,
  InternalListItemButton,
} from "./MenuListItemButton";

export interface AppDrawerMenuItemProps {
  item: CustomDrawerMenuItem;
}

const AppDrawerMenuItem = (props: AppDrawerMenuItemProps) => {
  const userToken = useSelector((state: RootState) => state.user.token);

  return (
    <>
      {props.item.protected ? (
        <>
          {!userToken ? (
            <></>
          ) : (
            <>
              {props.item.isExternakLink ? (
                <ExternalListItemButton item={props.item} />
              ) : (
                <InternalListItemButton item={props.item} />
              )}
            </>
          )}
        </>
      ) : (
        <>
          {props.item.isExternakLink ? (
            <ExternalListItemButton item={props.item} />
          ) : (
            <InternalListItemButton item={props.item} />
          )}
        </>
      )}
    </>
  );
};

export { AppDrawerMenuItem };
