import { useEffect, useState } from "react";
import {
  AbstractMonitorMessage,
  AbstractStatusPayload,
  CabinSwitch,
  HhLimitation,
  InputInterface,
  MonitorAutoTiltDown,
  OutputA,
  OutputB,
  PlatformSensor,
  Sensors,
  StatusPayloadV0,
  StatusPayloadV1,
  SystemLimitation,
} from "../../../cobra-backend-client";
import { MonitorErrorCode } from "../../../models/dto/ws/monitoring/MonitorErrorCode";
import { RssiZoneEnum } from "../../../models/dto/ws/monitoring/RssiZoneEnum";
import { DeviceStatusController } from "./DeviceStatusController";
import { DeviceStatusHandheld } from "./DeviceStatusHandheld";
import { DeviceStatusPlatformSensorContainer } from "./DeviceStatusPlatformSensorContainer";
import { DeviceStatusSystem } from "./DeviceStatusSystem";

interface Props {
  monitorMessage: AbstractMonitorMessage;
}

const DeviceStatus = (props: Props) => {
  const [activatedSensors, setActivatedSensors] = useState<Sensors>();
  const [errorCode, setErrorCode] = useState<MonitorErrorCode>();
  const [hhLimitation, setHhLimitation] = useState<HhLimitation>();
  const [systemLimitation, setSystemLimitation] = useState<SystemLimitation>();
  const [rssiZone, setRssiZone] = useState<RssiZoneEnum>();
  const [hhButtons, setHhButtons] = useState<boolean[]>();
  const [outputA, setOutputA] = useState<OutputA>();
  const [outputB, setOutputB] = useState<OutputB>();
  const [platformAngle, setPlatformAngle] = useState<number>();
  const [handheldSignal, setHandheldSignal] = useState<number>();
  const [wlanIndex, setWlanIndex] = useState<number>();
  const [batteryLevel, setBatteryLevel] = useState<number>();
  const [autotiltDown, setAutotiltDown] = useState<MonitorAutoTiltDown>();
  const [cabinSwitch, setCabinSwitch] = useState<CabinSwitch>();
  const [inputInterface, setInputInterface] = useState<InputInterface>();
  const [platformSensor, setPlatformSensor] = useState<PlatformSensor>();

  useEffect(() => {
    if (props.monitorMessage) {
      const abstractStatusPayload = props.monitorMessage
        .payload as AbstractStatusPayload;
      switch (abstractStatusPayload.version) {
        case "0.0.0":
          const statusPayloadV0 = abstractStatusPayload as StatusPayloadV0;
          setPropertieStatusPayloadV0(statusPayloadV0);
          break;
        case "1.0.0":
          const statusPaylodV1 = abstractStatusPayload as StatusPayloadV1;
          setPropertiesMonitorPayloadV1(statusPaylodV1);
          break;
      }
    }
  }, [props.monitorMessage]);

  const setPropertieStatusPayloadV0 = (statusPayloadV0: StatusPayloadV0) => {
    setActivatedSensors(statusPayloadV0.activatedSensors);
    setErrorCode(statusPayloadV0.errorCode);
    setHhLimitation(statusPayloadV0.hhLimitation);
    setRssiZone(statusPayloadV0.hhZone);
    setHhButtons(statusPayloadV0.hhButtons);
    setOutputA(statusPayloadV0.outputA);
    setOutputB(statusPayloadV0.outputB);
    setPlatformAngle(statusPayloadV0.platformAngle);
    setHandheldSignal(statusPayloadV0.hhSignal);
    setWlanIndex(statusPayloadV0.wlanIndex);
    setBatteryLevel(statusPayloadV0.vehicleBatteryLevel);
    setAutotiltDown(statusPayloadV0.autoTiltDown);
    setCabinSwitch(statusPayloadV0.cabinSwitch);
  };

  const setPropertiesMonitorPayloadV1 = (statusPayloadV1: StatusPayloadV1) => {
    setPropertieStatusPayloadV0(statusPayloadV1);
    setInputInterface(statusPayloadV1.inputInterface);
    setSystemLimitation(statusPayloadV1.systemLimitation);
    setPlatformSensor(statusPayloadV1.platformSensor);
  };

  return (
    <>
      <DeviceStatusSystem
        errorCode={errorCode}
        hhLimitation={hhLimitation}
        systemLimitation={systemLimitation}
      />

      <DeviceStatusHandheld
        hhLimitation={hhLimitation}
        rssiZone={rssiZone}
        handheldButtons={hhButtons}
        handheldSignal={handheldSignal}
      />

      <DeviceStatusController
        inputInterface={inputInterface}
        outputA={outputA}
        outputB={outputB}
        wlanIndex={wlanIndex}
        batteryLevel={batteryLevel}
        activatedSensors={activatedSensors}
        autotiltDown={autotiltDown}
        cabinSwitch={cabinSwitch}
      />

      <DeviceStatusPlatformSensorContainer
        platformSensor={platformSensor}
        platformAngle={platformAngle}
        activatedSensors={activatedSensors}
      />
    </>
  );
};

export { DeviceStatus };
