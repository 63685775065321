import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { COBRA_API } from "../api/config";
import {
  setCobraDeviceMcFwVersion,
  setCobraDeviceScFwVersion,
  setCobraPlSensorMcFwVersion,
  setCobraPlSensorScFwVersion,
} from "../stores/slices/firmwareVersionSlice";
import { getFirmwareDeviceMicroControllerVersions } from "./firmwareDeviceMicroControllerVersions";

export const retrieveFirmwareVersionFromServer = async (
  dispatch: Dispatch<AnyAction>
) => {
  try {
    const firmwareDeviceMicroControllerVersionDto = (
      await COBRA_API.FirmwareUpdate.retrieveFirmwareDeviceMicroControllerVersions()
    ).data;

    const {
      deviceMcFwVersion,
      deviceScFwVersion,
      plSensorMcFwVersion,
      plSensorScFwVersion,
    } = getFirmwareDeviceMicroControllerVersions(
      firmwareDeviceMicroControllerVersionDto
    );

    dispatch(setCobraDeviceMcFwVersion(deviceMcFwVersion));
    dispatch(setCobraDeviceScFwVersion(deviceScFwVersion));
    dispatch(setCobraPlSensorMcFwVersion(plSensorMcFwVersion));
    dispatch(setCobraPlSensorScFwVersion(plSensorScFwVersion));
  } catch (e: any) {}
};
