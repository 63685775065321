import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthorizationHeader } from "../api/config";
import { generateJwtToken } from "../api/login";
import { CustomAlert } from "../components/common/CustomAlert";
import { STORAGE_JWT_KEY } from "../constants/browserStorage";
import { LOGIN_PAGE } from "../constants/pages";
import { setUserToken } from "../stores/slices/userSlice";
import { RootState } from "../stores/store";
import { saveToStorage } from "../utils/browserStorage";

const SignInPage = () => {
  const userToken = useSelector((state: RootState) => state.user.token);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userToken) {
      navigate(LOGIN_PAGE);
    }
  }, [userToken]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("username")?.toString().trim();
    const password = data.get("password")?.toString().trim();
    const rememberMe = data.get("remember") ? true : false;

    if (username && password) {
      try {
        const token = (await generateJwtToken(username, password)).data;
        setAuthorizationHeader(token);
        dispatch(setUserToken(token));
        if (rememberMe) {
          saveToStorage(localStorage, STORAGE_JWT_KEY, token);
          saveToStorage(sessionStorage, STORAGE_JWT_KEY, null);
        } else {
          saveToStorage(sessionStorage, STORAGE_JWT_KEY, token);
          saveToStorage(localStorage, STORAGE_JWT_KEY, null);
        }

        navigate(LOGIN_PAGE, { replace: true });
      } catch (e: any) {
        setShowAlert(true);
      }
    }
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5">
        Login
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={true}
              color="primary"
              id="remember"
              name="remember"
            />
          }
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>

        <Button
          fullWidth
          onClick={() => navigate(LOGIN_PAGE, { replace: true })}
        >
          Continue without an account
        </Button>
      </Box>

      {showAlert && (
        <CustomAlert
          autoHideDurationMs={5000}
          onAlertClose={() => setShowAlert(false)}
          alertSeverity={"error"}
          alertText={"Incorrect username or password"}
        />
      )}
    </Box>
  );
};

export { SignInPage };
