import { Box, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}

const OutLogicOperation = (props: Props) => {
  const [selectedLogicOperation, setSelectedLogicOperation] = useState(
    props.updatedDeviceConfiguration.out?.logicOperation?.logicOperations?.[
      props.outAllocationIndex
    ] ?? undefined
  );

  useEffect(() => {}, [
    props.updatedDeviceConfiguration.out?.logicOperation?.logicOperations,
  ]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newLogicOperation: boolean
  ) => {
    if (newLogicOperation != null) {
      setSelectedLogicOperation(newLogicOperation);

      const newLogicOperations = [
        ...(props.updatedDeviceConfiguration.out?.logicOperation
          ?.logicOperations ?? []),
      ];
      newLogicOperations[props.outAllocationIndex] = newLogicOperation;

      const newDeviceConfiguration: DeviceConfiguration = {
        ...props.updatedDeviceConfiguration,
        out: {
          ...props.updatedDeviceConfiguration.out,
          logicOperation: {
            logicOperations: newLogicOperations,
          },
        },
      };

      props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
    }
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        Logic
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <ToggleButtonGroup
            color="info"
            size="small"
            value={selectedLogicOperation}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value={true}>AND</ToggleButton>
            <ToggleButton value={false}>OR</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
    </>
  );
};

export { OutLogicOperation };
