import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DrawerState {
  open: boolean;
}

const initialState: DrawerState = {
  open: false,
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setDrawerOpen } = drawerSlice.actions;

export default drawerSlice.reducer;
