export enum FirmwareUpdateMessageType {
  STATE_CHANGE = "Automatic update on the device started.",
  PROGRESS = "",
  ERROR_ERASE_FIRMWARE = "Error during the Delete old firmware process, update aborted.",
  ERROR_ERASE_EEPROM = "Error during the Delete EERPOM process , update aborted.",
  ERROR_PROGRAM_FIRMWARE = "Error during the Program new firmware process, update aborted.",
  ERROR_READ_FIRMWARE_FILE = "Error while reading the file.",
  ERROR_WRONG_TARGET = "Error, file for other in deviceType_t external flash memory, update aborted.",
  ERROR_RESEND_ERR = "Error, internal communication failure, update aborted.",
  ERROR_EXT_FLASH_ERR = "Error, external flash memory cannot be read, update aborted.",
}

export const getFirmwareUpdateMessageTypeValueByKey = (
  key: string
): FirmwareUpdateMessageType | undefined => {
  return (
    FirmwareUpdateMessageType as {
      [key: string]: FirmwareUpdateMessageType;
    }
  )[key];
};
