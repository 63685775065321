import { DeviceConfiguration, RssiZone } from "../cobra-backend-client";
import { RssiZoneVehicleTypeEnum } from "../models/RssiZoneVehicleTypeEnum";

export const get_POSITION_SENSOR_CONFIGURATION_SINGLE_POSITION_SENSOR = (
  deviceConfigration: DeviceConfiguration
): RssiZone => {
  const newRssiZoneOptions = {
    ...deviceConfigration.rssiZone.rssiZoneOptions,
    singlePositionSensModeEn: true,
  };

  const zoneDisable = { ...deviceConfigration.rssiZone.zoneDisable };

  const POSITION_SENSOR_CONFIGURATION_SINGLE_POSITION_SENSOR = {
    platform: {
      mean: -55,
      delta: 9,
    },
    back: {
      meanMin: -57,
      meanMax: -100,
      delta: 12,
      offset: 0,
    },
    side: {
      max: -34,
      delta: 10,
      offset: 0,
    },
    work: {
      mean: -60,
      offset: 0,
    },
    danger: {
      offsetToSideZone: 5,
    },
    hyst: {
      sideZoneActivation: 3,
      workZoneActivation: 3,
    },
    filter: {
      platformZone: 4,
      backZone: 4,
      sideZone: 4,
      workZone: 4,
    },
    rssiDetection: {
      filterUp: 2,
      filterDown: 16,
      filterUpNoTas: 1,
      filterDownNoTas: 4,
      offsetInitMean: -10,
      maxDeltaRssiHsRcv: 10,
      maxCntNoRssi: 3,
    },

    zoneDisable,

    rssiZoneOptions: {
      ...newRssiZoneOptions,
    },
    vehicleType: RssiZoneVehicleTypeEnum.SINGLE_POSITION_SENSOR,
  };

  return POSITION_SENSOR_CONFIGURATION_SINGLE_POSITION_SENSOR;
};

export const get_POSITION_SENSOR_CONFIGURATION_TRUCK_UP_TO_7_5M = (
  deviceConfigration: DeviceConfiguration
): RssiZone => {
  const newRssiZoneOptions = {
    ...deviceConfigration.rssiZone.rssiZoneOptions,
    singlePositionSensModeEn: false,
  };

  const zoneDisable = { ...deviceConfigration.rssiZone.zoneDisable };

  const POSITION_SENSOR_CONFIGURATION_TRUCK_UP_TO_7_5M: RssiZone = {
    platform: {
      mean: -55,
      delta: 9,
    },
    back: {
      meanMin: -57,
      meanMax: -100,
      delta: 12,
      offset: 0,
    },
    side: {
      max: -34,
      delta: 10,
      offset: 0,
    },
    work: {
      mean: -58,
      offset: 0,
    },
    danger: {
      offsetToSideZone: 5,
    },
    hyst: {
      sideZoneActivation: 3,
      workZoneActivation: 3,
    },
    filter: {
      platformZone: 4,
      backZone: 4,
      sideZone: 4,
      workZone: 4,
    },
    rssiDetection: {
      filterUp: 2,
      filterDown: 16,
      filterUpNoTas: 1,
      filterDownNoTas: 4,
      offsetInitMean: -10,
      maxDeltaRssiHsRcv: 10,
      maxCntNoRssi: 3,
    },

    zoneDisable,

    rssiZoneOptions: {
      ...newRssiZoneOptions,
    },
    vehicleType: RssiZoneVehicleTypeEnum.TRUCK_UP_TO_7M5,
  };

  return POSITION_SENSOR_CONFIGURATION_TRUCK_UP_TO_7_5M;
};

export const get_POSITION_SENSOR_CONFIGURATION_TRAILER_UP_TO_7_5M = (
  deviceConfigration: DeviceConfiguration
): RssiZone => {
  const newRssiZoneOptions = {
    ...deviceConfigration.rssiZone.rssiZoneOptions,
    singlePositionSensModeEn: false,
  };

  const zoneDisable = { ...deviceConfigration.rssiZone.zoneDisable };

  const POSITION_SENSOR_CONFIGURATION_TRAILER_UP_TO_7_5M: RssiZone = {
    platform: {
      mean: -50,
      delta: 12,
    },
    back: {
      meanMin: -57,
      meanMax: -100,
      delta: 15,
      offset: 0,
    },
    side: {
      max: -34,
      delta: 15,
      offset: 0,
    },
    work: {
      mean: -58,
      offset: 0,
    },
    danger: {
      offsetToSideZone: 5,
    },
    hyst: {
      sideZoneActivation: 3,
      workZoneActivation: 3,
    },
    filter: {
      platformZone: 4,
      backZone: 4,
      sideZone: 4,
      workZone: 4,
    },
    rssiDetection: {
      filterUp: 2,
      filterDown: 8,
      filterUpNoTas: 1,
      filterDownNoTas: 4,
      offsetInitMean: -10,
      maxDeltaRssiHsRcv: 10,
      maxCntNoRssi: 3,
    },
    zoneDisable,

    rssiZoneOptions: {
      ...newRssiZoneOptions,
    },
    vehicleType: RssiZoneVehicleTypeEnum.TRAILER_UP_TO_7M5,
  };

  return POSITION_SENSOR_CONFIGURATION_TRAILER_UP_TO_7_5M;
};

export const get_POSITION_SENSOR_CONFIGURATION_TRUCK_OVER_7_5M = (
  deviceConfigration: DeviceConfiguration
): RssiZone => {
  const newRssiZoneOptions = {
    ...deviceConfigration.rssiZone.rssiZoneOptions,
    singlePositionSensModeEn: false,
  };

  const zoneDisable = { ...deviceConfigration.rssiZone.zoneDisable };

  const POSITION_SENSOR_CONFIGURATION_TRUCK_OVER_7_5M: RssiZone = {
    platform: {
      mean: -55,
      delta: 9,
    },
    back: {
      meanMin: -57,
      meanMax: -100,
      delta: 12,
      offset: 0,
    },
    side: {
      max: -34,
      delta: 14,
      offset: 0,
    },
    work: {
      mean: -60,
      offset: 0,
    },
    danger: {
      offsetToSideZone: 5,
    },
    hyst: {
      sideZoneActivation: 2,
      workZoneActivation: 2,
    },
    filter: {
      platformZone: 4,
      backZone: 4,
      sideZone: 4,
      workZone: 4,
    },
    rssiDetection: {
      filterUp: 2,
      filterDown: 8,
      filterUpNoTas: 1,
      filterDownNoTas: 4,
      offsetInitMean: -10,
      maxDeltaRssiHsRcv: 10,
      maxCntNoRssi: 3,
    },

    zoneDisable,

    rssiZoneOptions: {
      ...newRssiZoneOptions,
    },
    vehicleType: RssiZoneVehicleTypeEnum.TRUCK_OVER_7M5,
  };

  return POSITION_SENSOR_CONFIGURATION_TRUCK_OVER_7_5M;
};

export const get_POSITION_SENSOR_CONFIGURATION_TRAILER_OVER_7_5M = (
  deviceConfigration: DeviceConfiguration
): RssiZone => {
  const newRssiZoneOptions = {
    ...deviceConfigration.rssiZone.rssiZoneOptions,
    singlePositionSensModeEn: false,
  };

  const zoneDisable = { ...deviceConfigration.rssiZone.zoneDisable };

  const POSITION_SENSOR_CONFIGURATION_TRAILER_OVER_7_5M: RssiZone = {
    platform: {
      mean: -55,
      delta: 9,
    },
    back: {
      meanMin: -57,
      meanMax: -100,
      delta: 12,
      offset: 0,
    },
    side: {
      max: -34,
      delta: 14,
      offset: 0,
    },
    work: {
      mean: -60,
      offset: 0,
    },
    danger: {
      offsetToSideZone: 5,
    },
    hyst: {
      sideZoneActivation: 2,
      workZoneActivation: 2,
    },
    filter: {
      platformZone: 4,
      backZone: 4,
      sideZone: 4,
      workZone: 4,
    },
    rssiDetection: {
      filterUp: 2,
      filterDown: 8,
      filterUpNoTas: 1,
      filterDownNoTas: 4,
      offsetInitMean: -10,
      maxDeltaRssiHsRcv: 10,
      maxCntNoRssi: 3,
    },
    zoneDisable,
    rssiZoneOptions: {
      ...newRssiZoneOptions,
    },
    vehicleType: RssiZoneVehicleTypeEnum.TRAILER_OVER_7M5,
  };

  return POSITION_SENSOR_CONFIGURATION_TRAILER_OVER_7_5M;
};

export const get_POSITION_SENSOR_CONFIGURATION_SEMI_TRAILER_OVER_10M = (
  deviceConfigration: DeviceConfiguration
): RssiZone => {
  const newRssiZoneOptions = {
    ...deviceConfigration.rssiZone.rssiZoneOptions,
    singlePositionSensModeEn: false,
  };

  const zoneDisable = { ...deviceConfigration.rssiZone.zoneDisable };

  const POSITION_SENSOR_CONFIGURATION_SEMI_TRAILER_OVER_10M: RssiZone = {
    platform: {
      mean: -55,
      delta: 9,
    },
    back: {
      meanMin: -57,
      meanMax: -100,
      delta: 12,
      offset: 0,
    },
    side: {
      max: -34,
      delta: 14,
      offset: 0,
    },
    work: {
      mean: -60,
      offset: 0,
    },
    danger: {
      offsetToSideZone: 5,
    },
    hyst: {
      sideZoneActivation: 2,
      workZoneActivation: 2,
    },
    filter: {
      platformZone: 4,
      backZone: 4,
      sideZone: 4,
      workZone: 4,
    },
    rssiDetection: {
      filterUp: 2,
      filterDown: 8,
      filterUpNoTas: 1,
      filterDownNoTas: 4,
      offsetInitMean: -10,
      maxDeltaRssiHsRcv: 10,
      maxCntNoRssi: 3,
    },
    zoneDisable,
    rssiZoneOptions: {
      ...newRssiZoneOptions,
    },
    vehicleType: RssiZoneVehicleTypeEnum.SEMITRAILER_OVER_10M,
  };

  return POSITION_SENSOR_CONFIGURATION_SEMI_TRAILER_OVER_10M;
};

//
