export const readFromStorage = (key: string) => {
  try {
    let value = localStorage.getItem(key);
    if (
      value !== null &&
      value !== "null" &&
      value !== undefined &&
      value !== ""
    ) {
      return JSON.parse(value);
    }
    value = sessionStorage.getItem(key);
    if (
      value !== null &&
      value !== "null" &&
      value !== undefined &&
      value !== ""
    ) {
      return JSON.parse(value);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export function saveToStorage<T>(
  storage: Storage,
  key: string,
  value: T
): void {
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (error) {}
}
