import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COBRA_API } from "../api/config";
import { DeviceConfiguration } from "../cobra-backend-client";
import { RootState } from "../stores/store";

export const useDeviceConfiguration = () => {
  const deviceId = useSelector((state: RootState) => state.device.deviceId);

  const [deviceConfiguration, setDeviceConfiguration] =
    useState<DeviceConfiguration>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!deviceId) return;

    getDeviceConfig(+deviceId);
  }, [deviceId]);

  const getDeviceConfig = async (deviceId: number) => {
    try {
      const signedDeviceConfiguration = (
        await COBRA_API.DeviceConfiguration.getSignedDeviceConfiguration(
          deviceId
        )
      ).data;
      setDeviceConfiguration(signedDeviceConfiguration.configuration);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return { deviceConfiguration, loading, error };
};
