import { Box, Grid, Typography } from "@mui/material";
import { renderOrderByIndexSvgButtonsComponent } from "../../../utils/renderOrderByIndexSvgButtonsComponent";
import RemoveIcon from "@mui/icons-material/Remove";

const NO_BUTTON_PRESSED_INDEX = -1;

interface Props {
  handheldButtons: boolean[];
}

const HandheldButton = (props: Props) => {
  const indexOfButtonToBeDisplayed =
    props.handheldButtons.lastIndexOf(true) ?? NO_BUTTON_PRESSED_INDEX;

  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Handheld button</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {indexOfButtonToBeDisplayed == NO_BUTTON_PRESSED_INDEX ? (
            <RemoveIcon />
          ) : (
            renderOrderByIndexSvgButtonsComponent(indexOfButtonToBeDisplayed)
          )}
        </Box>
      </Grid>
    </>
  );
};

export { HandheldButton };
