import { Checkbox } from "@mui/material";

interface Props {
  checked: boolean;
}

const ReadOnlyCheckBox = (props: Props) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Checkbox
        sx={{ m: 0, p: 0 }}
        size="small"
        checked={props.checked}
        onChange={handleCheckboxChange}
      />
    </>
  );
};

export { ReadOnlyCheckBox };
