import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography } from "@mui/material";
import { ChangeEvent, useRef } from "react";
import { ButtonWithTooltip } from "./ButtonWithTooltip";

interface Props {
  selectedFile: File | undefined;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile: () => void;
  fileFormat: string;
  buttonText: string;
  buttonTooltipText?: string;
}

const CustomFileUploader = (props: Props) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const clearFileInput = () => {
    if (!uploadInputRef.current) return;
    uploadInputRef.current.value = ""; // Clear the file input value
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <input
          ref={uploadInputRef}
          accept={props.fileFormat}
          type="file"
          onChange={(e) => {
            props.handleFileChange(e);
          }}
          style={{ display: "none" }}
        />

        <ButtonWithTooltip
          buttonText={props.buttonText}
          tooltipText={props.buttonTooltipText ? props.buttonTooltipText : ""}
          color="primary"
          variant="contained"
          startIcon={<AttachFileIcon />}
          onClick={() =>
            uploadInputRef.current && uploadInputRef.current.click()
          }
        />
      </Grid>
      <Grid item>
        <ButtonWithTooltip
          startIcon={<DeleteIcon />}
          buttonText="Delete file"
          tooltipText="Delete selected file"
          variant="contained"
          onClick={() => {
            clearFileInput();
            props.handleDeleteFile();
          }}
          color="error"
        />
      </Grid>
      <Grid item sm={12}>
        <Typography variant="body1" sx={{ fontWeight: "bold", my: 2 }}>
          {props.selectedFile
            ? `${props.selectedFile.name}`
            : "No file selected"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { CustomFileUploader };
