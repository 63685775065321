import { Box, Grid, Typography } from "@mui/material";
import { PlatformSensor } from "../../../cobra-backend-client";
import { ReadOnlyCheckBox } from "../../common/ReadOnlyCheckBox";

interface Props {
  platformSensor: PlatformSensor;
}

const PlatformSensorComponent = (props: Props) => {
  return (
    <>
      <Grid
        item
        xs={4}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>In 1..4 Single Click</Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {props.platformSensor.singleClick?.map((singleClickValue, index) => {
            const middleOfArrayIndex =
              props.platformSensor.singleClick!.length / 2 - 1;
            const isIndexMiddleOfTheArray = index === middleOfArrayIndex;
            const isChekcboxHidden = index > middleOfArrayIndex;
            return (
              <Box
                key={`single_click-${index}`}
                component="span"
                sx={{
                  pr: isIndexMiddleOfTheArray ? 1.5 : 0,
                  visibility: isChekcboxHidden ? "hidden" : "visible",
                }}
              >
                <ReadOnlyCheckBox checked={singleClickValue} />
              </Box>
            );
          })}
        </Box>
      </Grid>

      <Grid
        item
        xs={4}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>In 1..4 Double Click</Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {props.platformSensor.doubleClick?.map((doubleClickValue, index) => {
            const middleOfArrayIndex =
              props.platformSensor.doubleClick!.length / 2 - 1;
            const isIndexMiddleOfTheArray = index === middleOfArrayIndex;
            const isChekcboxHidden = index > middleOfArrayIndex;
            return (
              <Box
                key={`double-click-${index}`}
                component="span"
                sx={{
                  pr: isIndexMiddleOfTheArray ? 1.5 : 0,
                  visibility: isChekcboxHidden ? "hidden" : "visible",
                }}
              >
                <ReadOnlyCheckBox checked={doubleClickValue} />
              </Box>
            );
          })}
        </Box>
      </Grid>
    </>
  );
};

export { PlatformSensorComponent };
