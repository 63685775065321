import { FormControlLabel, Radio } from "@mui/material";

interface Props {
  value: string;
  checked: boolean;
  imageName: string;
  label?: string;
}

const CustomImageRadio = (props: Props) => {
  let image = "";
  try {
    image = require(`../../assets/${props.imageName}`);
  } catch (e) {}

  return (
    <FormControlLabel
      value={props.value}
      checked={props.checked}
      control={<Radio />}
      label={
        image ? (
          <img
            src={image}
            alt={props.label ?? ""}
            style={{
              maxWidth: "200px",
              maxHeight: "150px",
              width: "auto",
              height: "auto",
            }}
          />
        ) : (
          <div>{props.label}</div>
        )
      }
      labelPlacement="top"
    />
  );
};

export { CustomImageRadio };
