import { Grid, Typography } from "@mui/material";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { AutomaticTiltDown } from "./AutomaticTiltDown";
import { CabinSwitchEnabled } from "./CabinSwitchEnabled";
import { ControlBoxMode } from "./ControlBoxMode";
import { TwoHEnabled } from "./TwoHEnabled";
import { CableControlModeEnum } from "../../../models/CableControlModeEnum";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const InputContainer = (props: Props) => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Input
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
        }}
      >
        <ControlBoxMode
          updatedDeviceConfiguration={props.updatedDeviceConfiguration}
          setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
        />
        <CabinSwitchEnabled
          updatedDeviceConfiguration={props.updatedDeviceConfiguration}
          setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
        />
        {props.updatedDeviceConfiguration?.cableControl?.cableControlMode !==
          CableControlModeEnum.MODE_2 && (
          <TwoHEnabled
            updatedDeviceConfiguration={props.updatedDeviceConfiguration}
            setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
          />
        )}
        <AutomaticTiltDown
          updatedDeviceConfiguration={props.updatedDeviceConfiguration}
          setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
        />
      </Grid>
    </>
  );
};

export { InputContainer };
