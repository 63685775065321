import { FirmwareDeviceMicroControllerVersionDto } from "../cobra-backend-client";
import { DeviceMicroController } from "../models/dto/web/DeviceMicroController";

export const getFirmwareDeviceMicroControllerVersions = (
  firmwareDeviceMicroControllerVersionDtoSet: Set<FirmwareDeviceMicroControllerVersionDto>
) => {
  const firmwareDeviceMicroControllerVersionDto = Array.from(
    firmwareDeviceMicroControllerVersionDtoSet
  );
  const deviceMcFwVersion = firmwareDeviceMicroControllerVersionDto.find(
    (fw) => fw.deviceMicroController === DeviceMicroController.COBRA_DEVICE_MC
  )?.firmwareVersion;
  const deviceScFwVersion = firmwareDeviceMicroControllerVersionDto.find(
    (fw) => fw.deviceMicroController === DeviceMicroController.COBRA_DEVICE_SC
  )?.firmwareVersion;
  const plSensorMcFwVersion = firmwareDeviceMicroControllerVersionDto.find(
    (fw) =>
      fw.deviceMicroController === DeviceMicroController.COBRA_PL_SENSOR_MC
  )?.firmwareVersion;
  const plSensorScFwVersion = firmwareDeviceMicroControllerVersionDto.find(
    (fw) =>
      fw.deviceMicroController === DeviceMicroController.COBRA_PL_SENSOR_SC
  )?.firmwareVersion;

  return {
    deviceMcFwVersion,
    deviceScFwVersion,
    plSensorMcFwVersion,
    plSensorScFwVersion,
  };
};
