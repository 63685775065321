import LockIcon from "@mui/icons-material/Lock";
import {
  DeviceConfiguration,
  OutputConfig,
} from "../../../cobra-backend-client";
import { Grid, Switch } from "@mui/material";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}

const OutLockAndSaveZone = (props: Props) => {
  const onAutoLockChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    newOutputConfigTable[props.outAllocationIndex].alock = checked;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  const onMLockChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    newOutputConfigTable[props.outAllocationIndex].mlock = checked;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  const onSaveZoneChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    newOutputConfigTable[props.outAllocationIndex].sideZone = checked;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        Auto Lock
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={
            props.updatedDeviceConfiguration.out?.outputConfigTable?.[
              props.outAllocationIndex
            ].alock
          }
          onChange={onAutoLockChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <LockIcon sx={{ mr: 1 }} /> m. Lock
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={
            props.updatedDeviceConfiguration.out?.outputConfigTable?.[
              props.outAllocationIndex
            ].mlock
          }
          onChange={onMLockChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        Save-Zone
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={
            props.updatedDeviceConfiguration.out?.outputConfigTable?.[
              props.outAllocationIndex
            ].sideZone
          }
          onChange={onSaveZoneChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
    </>
  );
};

export { OutLockAndSaveZone };
