import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery20Icon from "@mui/icons-material/Battery20";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery80Icon from "@mui/icons-material/Battery80";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  batteryLevel: number;
}

const BatteryLevel = (props: Props) => {
  const [batteryPercentage, setBatteryPercentage] = useState(0);
  const [batteryLevel, setBatteryLevel] = useState("");

  useEffect(() => {
    const voltageValue = +(props.batteryLevel / 1000).toFixed(2);
    setBatteryLevel(voltageValue.toFixed(1) + "V");

    if (voltageValue > 17) {
      if (voltageValue > 22.0 && voltageValue <= 23.0) {
        setBatteryPercentage(25);
      } else if (voltageValue > 23 && voltageValue <= 24.0) {
        setBatteryPercentage(50);
      } else if (voltageValue > 24 && voltageValue <= 25) {
        setBatteryPercentage(75);
      } else if (voltageValue > 25) {
        setBatteryPercentage(100);
      } else {
        setBatteryPercentage(0);
      }
    } else {
      if (voltageValue > 11.0 && voltageValue <= 11.5) {
        setBatteryPercentage(25);
      } else if (voltageValue > 11.5 && voltageValue <= 12.0) {
        setBatteryPercentage(50);
      } else if (voltageValue > 12.0 && voltageValue <= 12.5) {
        setBatteryPercentage(75);
      } else if (voltageValue > 12.5 && voltageValue <= 17.0) {
        setBatteryPercentage(100);
      } else {
        setBatteryPercentage(0);
      }
    }
  }, [props.batteryLevel]);

  let BatteryIcon;

  switch (batteryPercentage) {
    case 25:
      BatteryIcon = Battery20Icon;
      break;
    case 50:
      BatteryIcon = Battery50Icon;
      break;
    case 75:
      BatteryIcon = Battery80Icon;
      break;
    case 100:
      BatteryIcon = BatteryFullIcon;
      break;

    default:
      BatteryIcon = Battery0BarIcon;
      break;
  }

  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Vehicle battery</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        {batteryLevel != "" && (
          <>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <BatteryIcon sx={{ mr: 1 }} />
              <Typography>{batteryLevel} </Typography>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};

export { BatteryLevel };
