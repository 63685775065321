export enum MonitorErrorCode {
  NO_ERROR = 0,
  IO_MONITOR_A_ERR_REG = 1,
  IO_MONITOR_B_ERR_REG = 2,
  IO_MONITOR_A_SHUTDOWN = 3,
  IO_MONITOR_B_SHUTDOWN = 4,
  OVER_CURRENT_A = 5,
  OVER_CURRENT_B = 6,
  MCUCOM = 7,
  PLAUSIBILITYCHECK = 8,
  MAIN_STATE = 9,
  HS_STOP = 10,
  LEARNMODE = 11,
  IMU = 12,
  INPUT_INTERFACE = 13,
}

export const MonitorErrorCodeMessages: Record<MonitorErrorCode, string> = {
  [MonitorErrorCode.NO_ERROR]: "OK",
  [MonitorErrorCode.IO_MONITOR_A_ERR_REG]: "Error IO monitor A",
  [MonitorErrorCode.IO_MONITOR_B_ERR_REG]: "Error IO monitor B",
  [MonitorErrorCode.IO_MONITOR_A_SHUTDOWN]: "Error IO monitor A",
  [MonitorErrorCode.IO_MONITOR_B_SHUTDOWN]: "Error IO monitor B",
  [MonitorErrorCode.OVER_CURRENT_A]: "Error overcurrent A",
  [MonitorErrorCode.OVER_CURRENT_B]: "Error overcurrent B",
  [MonitorErrorCode.MCUCOM]: "Error internal communication",
  [MonitorErrorCode.PLAUSIBILITYCHECK]: "Error plausibility check",
  [MonitorErrorCode.MAIN_STATE]: "Error main state",
  [MonitorErrorCode.HS_STOP]: "Error handheld",
  [MonitorErrorCode.LEARNMODE]: "Error learn mode",
  [MonitorErrorCode.IMU]: "Error Platformsensor",
  [MonitorErrorCode.INPUT_INTERFACE]: "Error Input Interface",
};
