import { Box } from "@mui/material";
import { customizedTheme } from "../../theme/theme";

interface Props {
  children?: React.ReactNode;
}

const DrawerHeader = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: customizedTheme.spacing(0, 1),
        // necessary for content to be below app bar
        ...customizedTheme.mixins.toolbar,
        justifyContent: "flex-end",
      }}
    >
      {props.children}
    </Box>
  );
};

export { DrawerHeader };
