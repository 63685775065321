import { Alert, Grid, LinearProgress } from "@mui/material";
import { ReactNode } from "react";
import { DeviceConfiguration } from "../../cobra-backend-client";
import { useDeviceConfiguration } from "../../hooks/useDeviceConfiguration";

interface Props {
  children: (deviceConfiguration: DeviceConfiguration) => ReactNode;
}

const DeviceConfigWrapper = (props: Props) => {
  const { deviceConfiguration, loading, error } = useDeviceConfiguration();

  return (
    <>
      {!loading && !error && deviceConfiguration != null ? (
        props.children(deviceConfiguration)
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {loading && <LinearProgress />}
            {!loading && error && (
              <Alert severity="error">
                Configuration cannot be exported: {error}
              </Alert>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { DeviceConfigWrapper };
