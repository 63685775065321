import SignalWifi0BarIcon from "@mui/icons-material/SignalWifi0Bar";
import SignalWifi1BarIcon from "@mui/icons-material/SignalWifi1Bar";
import SignalWifi3BarIcon from "@mui/icons-material/SignalWifi3Bar";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  handheldSignal: number;
}

const HandheldSignal = (props: Props) => {
  const [signalBarsNumber, setSignalBarsNumber] = useState<number>();

  useEffect(() => {
    if (props.handheldSignal > -60) {
      setSignalBarsNumber(3);
    } else if (props.handheldSignal >= -67 && props.handheldSignal <= -60) {
      setSignalBarsNumber(2);
    } else if (props.handheldSignal >= -75 && props.handheldSignal < -67) {
      setSignalBarsNumber(1);
    } else {
      setSignalBarsNumber(0);
    }
  }, [props.handheldSignal]);

  let WifiIcon;

  switch (signalBarsNumber) {
    case 1:
      WifiIcon = SignalWifi1BarIcon;
      break;
    case 2:
      WifiIcon = SignalWifi3BarIcon;
      break;
    case 3:
      WifiIcon = SignalWifi4BarIcon;
      break;
    default:
      WifiIcon = SignalWifi0BarIcon;
      break;
  }
  return (
    <>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Handheld signal</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <WifiIcon sx={{ mr: 1 }} />
          <Typography>{props.handheldSignal} dBm </Typography>
        </Box>
      </Grid>
    </>
  );
};

export { HandheldSignal };
