import { Grid, Typography } from "@mui/material";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { OutAllocation } from "./OutAllocation";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const OUT_ALLOCATIONS_INDEXES = [0, 1, 2, 3, 4, 5, 6, 7];

const OutAllocationContainer = (props: Props) => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        OUT Allocation
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
        }}
      >
        {OUT_ALLOCATIONS_INDEXES.map((i) => (
          <OutAllocation
            key={`OUT_INDEX_${i}`}
            updatedDeviceConfiguration={props.updatedDeviceConfiguration}
            setUpdatedDeiceConfiguration={props.setUpdatedDeiceConfiguration}
            outAllocationIndex={i}
          />
        ))}
      </Grid>
    </>
  );
};

export { OutAllocationContainer };
