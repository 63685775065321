import { useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import { AngleSelector } from "./AngleSelector";
import { Grid, Typography } from "@mui/material";
import {
  OUT_ALLOCATION_FLASHLIGHT_ANGLE_2_INDEX,
  OUT_ALLOCATION_FLASHLIGHT_ANGLE_3_INDEX,
  OUT_ALLOCATION_FLASHLIGHT_ANGLE_4_INDEX,
} from "../../../constants/common";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const AngleDefinition = (props: Props) => {
  const [angle2MinValue, setAngle2MinValue] = useState(
    props.updatedDeviceConfiguration.angleSector?.minDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_2_INDEX
    ] ?? 0
  );

  const [angle2MaxValue, setAngle2MaxValue] = useState(
    props.updatedDeviceConfiguration.angleSector?.minDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_2_INDEX
    ] ?? 0
  );

  const [angle3MinValue, setAngle3MinValue] = useState(
    props.updatedDeviceConfiguration.angleSector?.minDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_3_INDEX
    ] ?? 0
  );
  const [angle3MaxValue, setAngle3MaxValue] = useState(
    props.updatedDeviceConfiguration.angleSector?.minDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_3_INDEX
    ] ?? 0
  );
  const [angle4MinValue, setAngle4MinValue] = useState(
    props.updatedDeviceConfiguration.angleSector?.minDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_4_INDEX
    ] ?? 0
  );
  const [angle4MaxValue, setAngle4MaxValue] = useState(
    props.updatedDeviceConfiguration.angleSector?.minDeg?.[
      OUT_ALLOCATION_FLASHLIGHT_ANGLE_4_INDEX
    ] ?? 0
  );

  const updateAngles = (
    anglesIndex: number,
    minValue: number,
    maxValue: number
  ) => {
    const newMinDeg = [
      ...(props.updatedDeviceConfiguration.angleSector?.minDeg ?? []),
    ];
    newMinDeg[anglesIndex] = minValue;

    const newMaxDeg = [
      ...(props.updatedDeviceConfiguration.angleSector?.maxDeg ?? []),
    ];
    newMaxDeg[anglesIndex] = maxValue;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      angleSector: {
        ...props.updatedDeviceConfiguration.angleSector,
        minDeg: newMinDeg,
        maxDeg: newMaxDeg,
      },
    };
    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Angle definition
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          mb: 2,
        }}
      >
        <AngleSelector
          minValue={angle2MinValue}
          maxValue={angle2MaxValue}
          setMinValue={setAngle2MinValue}
          setMaxValue={setAngle2MaxValue}
          mainTitle={`Angle ${OUT_ALLOCATION_FLASHLIGHT_ANGLE_2_INDEX + 1}`}
          dialogTitle="Angle settings"
          updateDeviceConfiguration={() =>
            updateAngles(
              OUT_ALLOCATION_FLASHLIGHT_ANGLE_2_INDEX,
              angle2MinValue,
              angle2MaxValue
            )
          }
        />
        <AngleSelector
          minValue={angle3MinValue}
          maxValue={angle3MaxValue}
          setMinValue={setAngle3MinValue}
          setMaxValue={setAngle3MaxValue}
          mainTitle={`Angle ${OUT_ALLOCATION_FLASHLIGHT_ANGLE_3_INDEX + 1}`}
          dialogTitle="Angle settings"
          updateDeviceConfiguration={() =>
            updateAngles(
              OUT_ALLOCATION_FLASHLIGHT_ANGLE_3_INDEX,
              angle3MinValue,
              angle3MaxValue
            )
          }
        />
        <AngleSelector
          minValue={angle4MinValue}
          maxValue={angle4MaxValue}
          setMinValue={setAngle4MinValue}
          setMaxValue={setAngle4MaxValue}
          mainTitle={`Angle ${OUT_ALLOCATION_FLASHLIGHT_ANGLE_4_INDEX + 1}`}
          dialogTitle="Angle settings"
          updateDeviceConfiguration={() =>
            updateAngles(
              OUT_ALLOCATION_FLASHLIGHT_ANGLE_4_INDEX,
              angle4MinValue,
              angle4MaxValue
            )
          }
        />
      </Grid>
    </>
  );
};

export { AngleDefinition };
