import { Grid, Switch, Typography } from "@mui/material";
import {
  DeviceConfiguration,
  OutputConfig,
} from "../../../cobra-backend-client";
import { renderOrderByIndexSvgButtonsComponent } from "../../../utils/renderOrderByIndexSvgButtonsComponent";
import { Fragment } from "react";

const HS_BUTTONS_SVG_INDEXES = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
];

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}

const OutRadioControl = (props: Props) => {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    buttonIndex: number
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    if (newOutputConfigTable[props.outAllocationIndex].hsBtn != null) {
      newOutputConfigTable[props.outAllocationIndex].hsBtn![buttonIndex] =
        checked;
    }

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography variant="h6">Radio Control</Typography>
      </Grid>

      {HS_BUTTONS_SVG_INDEXES.map((index) => (
        <Fragment key={`HS_BUTTON_INDEX_${index}`}>
          <Grid
            item
            xs={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            {renderOrderByIndexSvgButtonsComponent(index)}
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Switch
              checked={
                props.updatedDeviceConfiguration.out?.outputConfigTable?.[
                  props.outAllocationIndex
                ].hsBtn?.[index]
              }
              onChange={(e, checked) => onChange(e, checked, index)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Fragment>
      ))}
    </>
  );
};

export { OutRadioControl };
