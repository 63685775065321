import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { decodeJwtToken } from "../../utils/jwt";

interface UserState {
  token: string | null;
  username: string | undefined;
  manufacturerId: string | undefined;
  manufacturerName: string | undefined;
  isSchmidigerUser: boolean;
}

const initialState: UserState = {
  token: null,
  username: undefined,
  manufacturerId: undefined,
  manufacturerName: undefined,
  isSchmidigerUser: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      if (action.payload) {
        const decodedToken = decodeJwtToken(action.payload);
        state.username = decodedToken?.sub;
        state.manufacturerId = decodedToken?.manufacturerId;
        state.manufacturerName = decodedToken?.manufacturerName;
        state.isSchmidigerUser =
          decodedToken?.projectId === SCHMIDIGER_PROJECT_ID;
      } else {
        state.username = undefined;
        state.manufacturerId = undefined;
        state.manufacturerName = undefined;
        state.isSchmidigerUser = false;
      }
    },
  },
});

export const { setUserToken } = userSlice.actions;

export default userSlice.reducer;

const SCHMIDIGER_PROJECT_ID = "390";
