import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  wlanIndex: number;
}

const WlanName = (props: Props) => {
  const [wlanName, setWlanName] = useState("");

  useEffect(() => {
    switch (props.wlanIndex) {
      case 3:
        setWlanName("WLAN 3");
        break;
      case 2:
        setWlanName("WLAN 2");
        break;
      case 1:
      default:
        setWlanName("WLAN 1");
    }
  }, [props.wlanIndex]);

  return (
    <>
      <Grid item xs={9} sm={6}>
        <Typography>Controller WLAN</Typography>
      </Grid>
      {wlanName != "" && (
        <Grid item xs={3} sm={6} textAlign="right">
          {wlanName}
        </Grid>
      )}
    </>
  );
};

export { WlanName };
