import { Grid, Typography } from "@mui/material";
import {
  CabinSwitch,
  InputInterface,
  MonitorAutoTiltDown,
  OutputA,
  OutputB,
  Sensors,
} from "../../../cobra-backend-client";
import { InputInterfaceComponent } from "./InputInterfaceComponent";
import { OutOutput } from "./OutOutput";
import { WlanName } from "./WlanName";
import { BatteryLevel } from "./BatteryLevel";
import { AutotiltDown } from "./AutotiltDown";
import { CabinSwitchComponent } from "./CabinSwitchComponent";

interface Props {
  inputInterface: InputInterface | undefined;
  outputA: OutputA | undefined;
  outputB: OutputB | undefined;
  wlanIndex: number | undefined;
  batteryLevel: number | undefined;
  autotiltDown: MonitorAutoTiltDown | undefined;
  activatedSensors: Sensors | undefined;
  cabinSwitch: CabinSwitch | undefined;
}

const DeviceStatusController = (props: Props) => {
  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Controller
        </Typography>
        <Grid container rowSpacing={1}>
          {props.inputInterface != null && (
            <InputInterfaceComponent inputInterface={props.inputInterface} />
          )}
          {props.outputA != null && props.outputB != null && (
            <OutOutput outputA={props.outputA} outputB={props.outputB} />
          )}

          {props.wlanIndex != null && <WlanName wlanIndex={props.wlanIndex} />}
          {props.batteryLevel != null && (
            <BatteryLevel batteryLevel={props.batteryLevel} />
          )}
          {props.autotiltDown != null &&
            props.activatedSensors?.autotiltDownSwitch != null && (
              <AutotiltDown
                autotiltDown={props.autotiltDown}
                autotiltDownSwitchSensor={
                  props.activatedSensors.autotiltDownSwitch
                }
              />
            )}
          {props.cabinSwitch && props.activatedSensors?.cabinSwitch != null && (
            <CabinSwitchComponent
              cabinSwitch={props.cabinSwitch}
              cabinSwitchSensor={props.activatedSensors.cabinSwitch}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { DeviceStatusController };
