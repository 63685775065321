import { Grid, Typography } from "@mui/material";
import { PlatformSensor, Sensors } from "../../../cobra-backend-client";
import { PlatformAngle } from "./PlatformAngle";
import { PlatformSensorComponent } from "./PlatformSensorComponent";

interface Props {
  platformAngle: number | undefined;
  activatedSensors: Sensors | undefined;
  platformSensor: PlatformSensor | undefined;
}

const DeviceStatusPlatformSensorContainer = (props: Props) => {
  return (
    <>
      {props.activatedSensors?.plSensor && (
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h4">Platformsensor</Typography>
            <Grid container rowSpacing={1}>
              {props.platformAngle != null &&
                props.activatedSensors?.plSensor != null && (
                  <PlatformAngle
                    platformAngle={props.platformAngle}
                    plSensor={props.activatedSensors.plSensor}
                  />
                )}
              {props.platformSensor != null && (
                <PlatformSensorComponent
                  platformSensor={props.platformSensor}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { DeviceStatusPlatformSensorContainer };
