import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { DeviceConfiguration } from "../../../cobra-backend-client";
import {
  PlatformSensorOrientationEnum,
  getPlatformSensorOrientationEnumValues,
  mapPlatformSensorOrientationValueToLabel,
} from "../../../models/PlatformSensorOrientationEnum";
import { CustomFab } from "../../common/CustomFab";
import { CustomImageRadio } from "../../common/CustomImageRadio";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const PlatformSensorOrientation = (props: Props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOrientationValue, setSelectedOrientationValue] =
    useState<PlatformSensorOrientationEnum>();

  useEffect(() => {
    if (props.updatedDeviceConfiguration.imu?.orientation != null) {
      setSelectedOrientationValue(
        props.updatedDeviceConfiguration.imu?.orientation
      );
    }
  }, [props.updatedDeviceConfiguration.imu?.orientation]);

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const orientation = +value as PlatformSensorOrientationEnum;
    setSelectedOrientationValue(orientation);

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      imu: {
        ...props.updatedDeviceConfiguration.imu,
        orientation: orientation,
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography>Platform Sensor orientation </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Typography sx={{ pr: 1 }}>
            {mapPlatformSensorOrientationValueToLabel(
              selectedOrientationValue
            )}
          </Typography>
          <CustomFab icon={<EditIcon />} onClick={() => setOpenDialog(true)} />
        </Box>
      </Grid>

      <Dialog fullScreen open={openDialog} onClose={() => setOpenDialog(false)}>
        <Box sx={{ m: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Platform Sensor orientation</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RadioGroup
              value={selectedOrientationValue}
              onChange={handleRadioChange}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  mb: 3,
                }}
              >
                {getPlatformSensorOrientationEnumValues().map((v) => {
                  return (
                    <Fragment key={`pso_${v}`}>
                      <CustomImageRadio
                        value={v}
                        checked={+v === selectedOrientationValue}
                        imageName={`pls_orientation${v}.jpeg`}
                        label={mapPlatformSensorOrientationValueToLabel(+v)}
                      />
                    </Fragment>
                  );
                })}
              </Box>
            </RadioGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button variant="contained" onClick={() => setOpenDialog(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export { PlatformSensorOrientation };
