import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { COBRA_API } from "../api/config";
import { Device } from "../cobra-backend-client";
import { DEVICE_STATUS_PAGE } from "../constants/pages";
import {
  setDeviceId,
  setDeviceType,
  setDeviceVersion,
  setIsDeviceOnline,
  setManufacturerId,
  setProjectId,
} from "../stores/slices/deviceSlice";
import { RootState } from "../stores/store";

const ConnectToDevicePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");

  const userManufacturerId = useSelector(
    (state: RootState) => state.user.manufacturerId
  );
  const userManufacturerName = useSelector(
    (state: RootState) => state.user.manufacturerName
  );

  const [currentDeviceId, setCurrentDeviceId] = useState<string | null>(
    deviceId
  );
  const [onlineDevices, setOnlineDevices] = useState<Device[]>([]);
  const [showAlertDeviceOffline, setShowAlertDeviceOffline] = useState(false);
  const [showAlertForbiddenToConnect, setShowAlertForbiddenToConnect] =
    useState(false);
  const [showAlertInvalidDeviceId, setShowAlertInvalidDeviceId] =
    useState(false);
  const [offlineDeviceId, setOfflineDeviceId] = useState("");

  useEffect(() => {
    if (!userManufacturerId) return;
    getOnlineDevices();
  }, [userManufacturerId]);

  const getOnlineDevices = async () => {
    try {
      const devices = (await COBRA_API.DeviceRegistry.getUserDevices()).data;
      const onlineDevices = devices.filter(
        (d) => d.online && d.manufacturerId == d.manufacturerId
      );
      setOnlineDevices(onlineDevices);
    } catch (e) {}
  };

  const onConnectClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    handleConnect();
  };

  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "NumpadEnter"].includes(event.key)) {
      event.preventDefault();
      handleConnect();
    }
  };

  const handleConnect = async () => {
    setShowAlertForbiddenToConnect(false);
    setShowAlertInvalidDeviceId(false);
    setShowAlertDeviceOffline(false);

    if (!currentDeviceId) return;
    const deviceId = +currentDeviceId;

    if (!deviceId) {
      setShowAlertInvalidDeviceId(true);
      return;
    }

    try {
      const deviceInformation = (
        await COBRA_API.DeviceRegistry.getDevice(+currentDeviceId)
      ).data;

      if (
        userManufacturerId != "" &&
        userManufacturerId != null &&
        deviceInformation.manufacturerId != userManufacturerId
      ) {
        setShowAlertForbiddenToConnect(true);
        return;
      }

      if (deviceInformation.online) {
        dispatch(setDeviceId(deviceInformation.deviceId?.toString()));
        dispatch(setManufacturerId(deviceInformation.manufacturerId));
        dispatch(setProjectId(deviceInformation.projectId));
        dispatch(setDeviceType(deviceInformation.type));
        dispatch(setIsDeviceOnline(deviceInformation.online));
        dispatch(setDeviceVersion(deviceInformation.version));
        navigate(DEVICE_STATUS_PAGE);
      }

      setOfflineDeviceId(currentDeviceId);
      setShowAlertDeviceOffline(true);
    } catch (e) {
      setOfflineDeviceId(currentDeviceId);
      setShowAlertDeviceOffline(true);
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Connect to device
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <TextField
          label=""
          size="small"
          value={currentDeviceId ?? ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentDeviceId(event.target.value);
          }}
          onKeyDown={onEnterKeyPress}
        />
        <Button variant="contained" onClick={(e) => onConnectClick(e)}>
          Connect
        </Button>
      </Stack>
      {userManufacturerName && (
        <Typography variant="h5">{`Online-Devices ${userManufacturerName}`}</Typography>
      )}
      {onlineDevices.map((d) => {
        const onlineDeviceId = d.deviceId?.toString();
        if (onlineDeviceId != null) {
          return (
            <Box key={onlineDeviceId}>
              <Button
                onClick={() => setCurrentDeviceId(onlineDeviceId)}
                size="small"
              >
                <Typography variant="h6" sx={{ mr: 0.5 }}>
                  {onlineDeviceId}
                </Typography>
                <AddCircleOutlineIcon />
              </Button>
            </Box>
          );
        }
      })}
      {showAlertDeviceOffline && (
        <Alert
          severity="error"
          onClose={() => {
            setShowAlertDeviceOffline(false);
          }}
          sx={{ mt: 2 }}
        >
          <AlertTitle>
            Device with id <strong> {offlineDeviceId} </strong> is offline
          </AlertTitle>
          You can give an internet connection on your Cobra 390 system by
          providing a mobile hotspot using your smartphone and the following
          credentials <br /> <strong>Network name:</strong> <br /> Cobra390
          <br /> <strong> Password:</strong> <br />
          87654321
        </Alert>
      )}
      {showAlertForbiddenToConnect && (
        <Alert
          severity="error"
          onClose={() => {
            setShowAlertForbiddenToConnect(false);
          }}
          sx={{ mt: 2 }}
        >
          Forbidden to connect to the device
        </Alert>
      )}

      {showAlertInvalidDeviceId && (
        <Alert
          severity="error"
          onClose={() => {
            setShowAlertInvalidDeviceId(false);
          }}
          sx={{ mt: 2 }}
        >
          Device id is not valid
        </Alert>
      )}
    </>
  );
};

export { ConnectToDevicePage };
