import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useDeviceMonitor } from "../../hooks/useDeviceMonitor";
import { RootState } from "../../stores/store";
import { customizedTheme, drawerWidth } from "../../theme/theme";
import { AppBar } from "./AppBar";
import { AppDrawer } from "./AppDrawer";
import { DrawerHeader } from "./DrawerHeader";

const MainWithDrawer = () => {
  const open = useSelector((state: RootState) => state.drawer.open);
  const isScreenSizeGreaterThanSm = useMediaQuery(
    customizedTheme.breakpoints.up("sm")
  );

  useDeviceMonitor();

  const bigScreenDesignDefault = isScreenSizeGreaterThanSm
    ? { px: customizedTheme.spacing(1) }
    : {};

  const bigScreenDesignDrawerOpen =
    isScreenSizeGreaterThanSm && open
      ? {
          marginLeft: `${drawerWidth}px`,
        }
      : {};

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar />
      <AppDrawer />
      <Box
        component="main"
        sx={{
          position: "relative",
          flexGrow: 1,
          ...bigScreenDesignDefault,
          ...bigScreenDesignDrawerOpen,
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export { MainWithDrawer };
