import { Grid, Switch } from "@mui/material";
import {
  DeviceConfiguration,
  OutputConfig,
} from "../../../cobra-backend-client";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
  outAllocationIndex: number;
}

const OutAutoTilt = (props: Props) => {
  const onAutoTiltUpChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    newOutputConfigTable[props.outAllocationIndex].autoTiltUp = checked;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  const onAutoTiltDownChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newOutputConfigTable = [
      ...(props.updatedDeviceConfiguration.out
        ?.outputConfigTable as OutputConfig[]),
    ];

    newOutputConfigTable[props.outAllocationIndex].autoTiltDown = checked;

    const newDeviceConfiguration: DeviceConfiguration = {
      ...props.updatedDeviceConfiguration,
      out: {
        ...props.updatedDeviceConfiguration.out,
        outputConfigTable: [...newOutputConfigTable],
      },
    };

    props.setUpdatedDeiceConfiguration(newDeviceConfiguration);
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        Autotilt Up
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={
            props.updatedDeviceConfiguration.out?.outputConfigTable?.[
              props.outAllocationIndex
            ].autoTiltUp
          }
          onChange={onAutoTiltUpChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        Autotilt Down
      </Grid>
      <Grid item xs={6} textAlign="right">
        <Switch
          checked={
            props.updatedDeviceConfiguration.out?.outputConfigTable?.[
              props.outAllocationIndex
            ].autoTiltDown
          }
          onChange={onAutoTiltDownChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
    </>
  );
};

export { OutAutoTilt };
