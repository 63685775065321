import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LinearProgress, Stack } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { COBRA_API } from "../../../api/config";
import {
  DeviceConfiguration,
  DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum,
  VariokeyItem,
} from "../../../cobra-backend-client";
import { RootState } from "../../../stores/store";
import { ButtonWithTooltip } from "../../common/ButtonWithTooltip";
import { CustomAlert } from "../../common/CustomAlert";
import { AngleDefinition } from "./AngleDefinition";
import { InputContainer } from "./InputContainer";
import { OutAllocationContainer } from "./OutAllocationContainer";
import { PlatformSensor } from "./PlatformSensor";
import { PositionSensor } from "./PositionSensor";
import { VariokeyContainer } from "./VariokeyContainer";

interface Props {
  deviceConfiguration: DeviceConfiguration;
  variokeys: VariokeyItem[];
}

const DeviceConfigurationSettingsContainer = (props: Props) => {
  const deviceId = useSelector((state: RootState) => state.device.deviceId);

  const [updatedDeviceConfiguration, setUpdatedDeiceConfiguration] =
    useState<DeviceConfiguration>(props.deviceConfiguration);
  const [
    hasDeviceConfigurationUpdateError,
    setHasDeviceConfigurationUpdateError,
  ] = useState(false);
  const [
    showDeviceConfigurationUpdateAlert,
    setShowDeviceConfigurationUpdateAlert,
  ] = useState(false);
  const [
    deviceConfigurationUpdateLoading,
    setDeviceConfigurationUpdateLoading,
  ] = useState(false);

  const resetUpdatedDeviceConfiguration = () => {
    setUpdatedDeiceConfiguration({ ...props.deviceConfiguration });
  };

  const updateDeviceConfiguraiton = async () => {
    if (!deviceId) return;
    setDeviceConfigurationUpdateLoading(true);
    try {
      const deviceConfigurationUpdateDto =
        await COBRA_API.DeviceConfiguration.updateDeviceConfiguration(
          +deviceId,
          updatedDeviceConfiguration
        );
      if (
        deviceConfigurationUpdateDto.data.deviceConfigurationUpdateResponse ===
        DeviceConfigurationUpdateDtoDeviceConfigurationUpdateResponseEnum.NoErr
      ) {
        setHasDeviceConfigurationUpdateError(false);
      } else {
        setHasDeviceConfigurationUpdateError(true);
      }
    } catch (e) {
      setHasDeviceConfigurationUpdateError(true);
    }
    setShowDeviceConfigurationUpdateAlert(true);
    setDeviceConfigurationUpdateLoading(false);
  };

  return (
    <>
      <PositionSensor
        updatedDeviceConfiguration={updatedDeviceConfiguration}
        setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
        deviceConfiguration={props.deviceConfiguration}
      />
      <PlatformSensor
        updatedDeviceConfiguration={updatedDeviceConfiguration}
        setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
      />
      <OutAllocationContainer
        updatedDeviceConfiguration={updatedDeviceConfiguration}
        setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
      />
      {updatedDeviceConfiguration.imu?.plSensEnable && (
        <AngleDefinition
          updatedDeviceConfiguration={updatedDeviceConfiguration}
          setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
        />
      )}
      <InputContainer
        updatedDeviceConfiguration={updatedDeviceConfiguration}
        setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
      />
      <VariokeyContainer
        updatedDeviceConfiguration={updatedDeviceConfiguration}
        setUpdatedDeiceConfiguration={setUpdatedDeiceConfiguration}
        variokeys={props.variokeys}
      />

      {showDeviceConfigurationUpdateAlert && (
        <CustomAlert
          autoHideDurationMs={5000}
          onAlertClose={() => setShowDeviceConfigurationUpdateAlert(false)}
          alertSeverity={
            hasDeviceConfigurationUpdateError ? "error" : "success"
          }
          alertText={
            hasDeviceConfigurationUpdateError
              ? "Configuration can not be updated"
              : "Configuration was updated successfully"
          }
        />
      )}
      {deviceConfigurationUpdateLoading && <LinearProgress sx={{ m: 2 }} />}

      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={1}
        sx={{ mt: 2 }}
      >
        <ButtonWithTooltip
          buttonText="Cancel"
          variant="contained"
          tooltipText="Discard Changes"
          onClick={resetUpdatedDeviceConfiguration}
          disabled={deviceConfigurationUpdateLoading}
        />

        <ButtonWithTooltip
          startIcon={<CloudUploadIcon />}
          buttonText="Save to controller"
          variant="contained"
          tooltipText="Update Configuration"
          onClick={updateDeviceConfiguraiton}
          disabled={deviceConfigurationUpdateLoading}
        />
      </Stack>
    </>
  );
};

export { DeviceConfigurationSettingsContainer };
