import { configureStore } from "@reduxjs/toolkit";
import deviceReducer from "./slices/deviceSlice";
import drawerReducer from "./slices/drawerSlice";
import userReducer from "./slices/userSlice";
import firmwareVersionReducer from "./slices/firmwareVersionSlice";
import stompReducer from "./slices/stompSlice";

const store = configureStore({
  reducer: {
    device: deviceReducer,
    drawer: drawerReducer,
    user: userReducer,
    firmwareVersion: firmwareVersionReducer,
    stomp: stompReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export { store };
