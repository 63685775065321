import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  DeviceConfiguration,
} from "../../../cobra-backend-client";
import { CustomSlider } from "../../common/CustomSlider";
import { RssiZoneVehicleTypeEnum } from "../../../models/RssiZoneVehicleTypeEnum";

interface Props {
  updatedDeviceConfiguration: DeviceConfiguration;
  setUpdatedDeiceConfiguration: React.Dispatch<
    React.SetStateAction<DeviceConfiguration>
  >;
}

const PositionSensorZones = (props: Props) => {
  const [sideZoneOffset, setSideZoneOffset] = useState<number | undefined>(
    props.updatedDeviceConfiguration.rssiZone?.side?.offset
  );
  const [workZoneOffset, setWorkZoneOffset] = useState<number | undefined>(
    props.updatedDeviceConfiguration.rssiZone?.work?.offset
  );

  useEffect(() => {
    setSideZoneOffset(props.updatedDeviceConfiguration.rssiZone?.side?.offset);
  }, [props.updatedDeviceConfiguration.rssiZone?.side?.offset]);

  useEffect(() => {
    setWorkZoneOffset(props.updatedDeviceConfiguration.rssiZone?.work?.offset);
  }, [props.updatedDeviceConfiguration.rssiZone?.work?.offset]);

  const onSideZoneOffsetChange = (newValue: number) => {
    const newConfiguration = {
      ...props.updatedDeviceConfiguration,
      ...{
        rssiZone: {
          ...props.updatedDeviceConfiguration.rssiZone,
          side: {
            ...props.updatedDeviceConfiguration.rssiZone?.side,
            offset: newValue,
          },
        },
      },
    };
    props.setUpdatedDeiceConfiguration(newConfiguration);
  };

  const onWorkZoneOffsetChange = (newValue: number) => {
    const newConfiguration = {
      ...props.updatedDeviceConfiguration,
      ...{
        rssiZone: {
          ...props.updatedDeviceConfiguration.rssiZone,
          work: {
            ...props.updatedDeviceConfiguration.rssiZone?.work,
            offset: newValue,
          },
        },
      },
    };

    props.setUpdatedDeiceConfiguration(newConfiguration);
  };

  return (
    <>
      {props.updatedDeviceConfiguration.rssiZone?.vehicleType !==
        RssiZoneVehicleTypeEnum.CUSTOM_CONFIGURATION && (
        <>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              alignItems: {
                xs: "center",
              },
              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
              display: "flex",
            }}
          >
            <Typography>Side-Zone Size</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <CustomSlider
              value={sideZoneOffset}
              setValue={setSideZoneOffset}
              onChange={onSideZoneOffsetChange}
              min={-5}
              max={5}
              step={1}
              captionLeft="small"
              captionRight="large"
            />
          </Grid>
        </>
      )}

      {props.updatedDeviceConfiguration.rssiZone?.vehicleType !==
        RssiZoneVehicleTypeEnum.CUSTOM_CONFIGURATION &&
        props.updatedDeviceConfiguration.rssiZone?.vehicleType !==
          RssiZoneVehicleTypeEnum.SINGLE_POSITION_SENSOR && (
          <>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                alignItems: {
                  xs: "center",
                },
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
                display: "flex",
              }}
            >
              <Typography>Work-Zone Size</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              <CustomSlider
                value={workZoneOffset}
                setValue={setWorkZoneOffset}
                onChange={onWorkZoneOffsetChange}
                min={-5}
                max={5}
                step={1}
                captionLeft="small"
                captionRight="large"
              />
            </Grid>
          </>
        )}
    </>
  );
};

export { PositionSensorZones };
