import { Chip, Grid, Typography } from "@mui/material";
import { MonitorAutoTiltDown } from "../../../cobra-backend-client";

interface Props {
  autotiltDown: MonitorAutoTiltDown;
  autotiltDownSwitchSensor: boolean;
}

const AutotiltDown = (props: Props) => {
  return (
    <>
      {props.autotiltDownSwitchSensor && (
        <>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <Typography>Autotilt down input </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{
              alignItems: "center",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Chip
              label={props.autotiltDown.input ? "Active" : "Inactive"}
              variant="outlined"
              color={props.autotiltDown.input ? "success" : "error"}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export { AutotiltDown };
